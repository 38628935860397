import { useContext, useCallback } from 'react';
import { AppContext, ROLES } from '../AppContext';

export const useAuth = () => {
  const { isFirebaseInit, isSignedIn, signOut, userClaims, setUserClaims, setInitData, initData } =
    useContext(AppContext);
  const hasPermission = useCallback(
    (role: ROLES) => {
      if (!userClaims) {
        return false;
      } else {
        return userClaims.permissions.indexOf(role) >= 0;
      }
    },
    [userClaims]
  );
  return {
    isFirebaseInit,
    isSignedIn,
    signOut,
    hasPermission,
    setUserClaims,
    userClaims,
    setInitData,
    initData,
  };
};
