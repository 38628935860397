import { useEffect, FC, useMemo } from 'react';
import { Center, VStack, Button, Heading } from '@chakra-ui/react';
import { useAuth } from './hooks/useAuth';
import { Icon } from './Icon';
import { useGetInitDataQuery } from 'client/user';
import findIndex from 'lodash/findIndex';
import type { Team, InitData } from './AppContext';
import { useTranslation } from 'react-i18next';

const LoadInitData: FC = ({ children }) => {
  const { userClaims, setInitData, initData } = useAuth();
  const { t } = useTranslation('common');

  const [{ fetching, data, error }, reFetch] = useGetInitDataQuery({
    variables: {
      user_id: `${userClaims?.id}`,
    },
    pause: !userClaims,
  });

  const currentTeamId = useMemo(() => {
    return initData ? initData.currentTeam.id : undefined;
  }, [initData]);

  useEffect(() => {
    if (data) {
      console.log('Update InitData');
      const teams: Team[] = data.teams.map<Team>((i) => ({
        id: i.id,
        name: i.name,
        role: i.members[0].role_id,
        is_trial: i.is_trial || false,
        is_subscribed: i.is_subscribed || false,
        avatarPath: i.logo?.path,
        joined_at: i.members[0].created_at,
      }));
      const currentIndex = currentTeamId ? findIndex(teams, { id: currentTeamId }) : 0;
      const newInitData: InitData = {
        profile: data.profile!,
        teams: teams,
        currentTeam: teams[currentIndex >= 0 ? currentIndex : 0],
      };
      setInitData(newInitData);
    }
  }, [currentTeamId, data, setInitData]);

  if (fetching || !!error) {
    return (
      <Center h="100vh">
        {!error ? (
          <Icon />
        ) : (
          <VStack spacing="10">
            <Heading>{t('commonError')}</Heading>
            <Button onClick={() => reFetch()}>{t('reload')}</Button>
          </VStack>
        )}
      </Center>
    );
  }
  return <>{children}</>;
};

export default LoadInitData;
