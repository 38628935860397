import { useCallback, memo } from 'react';
import {
  Flex,
  FlexProps,
  Spacer,
  Stack,
  Text,
  useColorModeValue as mode,
  HStack,
  Skeleton,
  VStack,
  Button,
} from '@chakra-ui/react';
import {
  BiLayerPlus,
  BiUser,
  BiHome,
  BiNews,
  BiChalkboard,
  BiUserCircle,
  BiBuoy,
  BiCreditCardFront,
  // BiPlug,
} from 'react-icons/bi';
import { GrInstallOption } from 'react-icons/gr';
import { ColorModeSwitcher } from 'components/ColorModeSwitcher';
import usePWA from 'react-pwa-install-prompt';

import { AccountSwitcher } from './AccountSwitcher';
import { NavGroup } from './NavGroup';
import { NavItem } from './NavItem';

import { useAuth } from 'hooks/useAuth';
import { useTeam } from 'hooks/useTeam';
import { PATH } from 'Paths';
import { useTranslation } from 'react-i18next';

export type SubItem = {
  path: string;
  label: string;
};

type Item = {
  path: string;
  icon: React.ReactElement;
  label: string;
  subItems?: SubItem[];
};

type Group = {
  items: Item[];
  label?: string;
};

const NavDefault = memo(() => {
  const { getTeamPath } = useTeam();
  const { t } = useTranslation('sidebar');

  const data: Group[] = [
    {
      items: [
        {
          label: t('menuItems.getStarted'),
          path: getTeamPath(PATH.GET_STARTED),
          icon: <BiHome />,
        },
        {
          label: t('menuItems.dash'),
          path: getTeamPath(PATH.DASHBOARD),
          icon: <BiChalkboard />,
        },
        {
          label: t('menuItems.apps'),
          path: getTeamPath(PATH.APPS),
          icon: <BiLayerPlus />,
        },
      ],
    },
    {
      label: t('menuItems.manage'),
      items: [
        {
          label: t('menuItems.customers'),
          path: getTeamPath(PATH.CUSTOMERS),
          icon: <BiUserCircle />,
          subItems: [
            {
              label: t('menuItems.accounts'),
              path: getTeamPath(PATH.CUSTOMERS_ACCOUNT),
            },
          ],
        },
        {
          label: t('menuItems.settings'),
          path: getTeamPath(PATH.SETTINGS),
          icon: <BiUser />,
        },
        {
          label: t('menuItems.billing'),
          path: getTeamPath(PATH.BILLING),
          icon: <BiCreditCardFront />,
        },
        // {
        //   label: 'Audit Logs',
        //   path: getTeamPath(PATH.AUDIT_LOGS),
        //   icon: <BiPlug />,
        // },
      ],
    },
    {
      label: t('menuItems.support'),
      items: [
        {
          label: t('menuItems.document'),
          path: PATH.DOCUMENT,
          icon: <BiNews />,
        },
        {
          label: t('menuItems.help'),
          path: getTeamPath(PATH.SUPPORT),
          icon: <BiBuoy />,
        },
      ],
    },
  ];
  return (
    <Stack spacing="8" flex="1" pt="8">
      {data.map((g, index) =>
        !g.label ? (
          <Stack spacing="1" key={`gn-${index}`}>
            {g.items.map((i) => (
              <NavItem
                key={`nv-${i.path}`}
                icon={i.icon}
                label={i.label}
                href={i.path}
                subItems={i.subItems}
              />
            ))}
          </Stack>
        ) : (
          <NavGroup label={g.label} key={`gn-${index}`}>
            {g.items.map((i) => (
              <NavItem
                key={`nv-${i.path}`}
                icon={i.icon}
                label={i.label}
                href={i.path}
                subItems={i.subItems}
              />
            ))}
          </NavGroup>
        )
      )}
    </Stack>
  );
});

export const Sidebar = (props: FlexProps) => {
  const { isFirebaseInit } = useAuth();
  const { t } = useTranslation('sidebar');

  const renderSkeleton = useCallback(() => {
    return (
      <Stack spacing="4">
        <Skeleton height="60px" />
        <Skeleton height="20px" pt="2" />
        <Skeleton height="20px" />
        <Skeleton height="20px" />
        <Skeleton height="20px" />
        <Skeleton height="20px" />
      </Stack>
    );
  }, []);

  const { isStandalone, isInstallPromptSupported, promptInstall } = usePWA();

  const onClickInstall = async () => {
    const didInstall = await promptInstall();
    if (didInstall) {
      // User accepted PWA install
    }
  };

  return (
    <Flex
      h="100vh"
      bg={mode('gray.50', 'gray.800')}
      position="fixed"
      direction="column"
      width="64"
      {...props}>
      <Flex direction="column" flex="1" pt="5" pb="4" overflowY="auto" px="4" h="full">
        {isFirebaseInit ? (
          <>
            <AccountSwitcher />
            <NavDefault />
          </>
        ) : (
          renderSkeleton()
        )}

        <Spacer />
      </Flex>

      <VStack spacing="0">
        {isInstallPromptSupported && isStandalone && (
          <HStack p="4" alignItems="center" justifyContent="space-between">
            <Button
              rightIcon={<GrInstallOption />}
              variant="outline"
              size="sm"
              onClick={onClickInstall}>
              {t('menuItems.install')}
            </Button>
          </HStack>
        )}
        <HStack
          borderTopWidth="1px"
          w="full"
          px="2"
          flexShrink={0}
          p="4"
          alignItems="center"
          justifyContent="space-between">
          <Text>{t('menuItems.darkMode')}</Text>
          <ColorModeSwitcher />
        </HStack>
      </VStack>
    </Flex>
  );
};
