import {
  Menu,
  MenuDivider,
  MenuItem,
  MenuItemOption,
  MenuList,
  MenuOptionGroup,
  Text,
  useColorModeValue,
  Stack,
  Skeleton,
  Avatar,
} from '@chakra-ui/react';
import { useCallback } from 'react';
import { AccountSwitcherButton } from './AccountSwitcherButton';
import { useAuth } from 'hooks/useAuth';
import { useTeam } from 'hooks/useTeam';
import { useHistory } from 'react-router-dom';
import { PATH } from 'Paths';
import { useTranslation } from 'react-i18next';

export const AccountSwitcher = () => {
  const { signOut, initData, isFirebaseInit } = useAuth();
  const { switchTeam } = useTeam();
  const { push } = useHistory();
  const { t } = useTranslation('sidebar');

  const switchTeamWithId = useCallback(
    (teamId: string) => {
      switchTeam(teamId);
      push(`${PATH.DASHBOARD}?i=${teamId}`);
    },
    [push, switchTeam]
  );
  return (
    <Menu isLazy lazyBehavior="keepMounted">
      <AccountSwitcherButton />
      <MenuList shadow="lg" py="4" color={useColorModeValue('gray.600', 'gray.200')} px="3">
        {isFirebaseInit && !initData && (
          <Stack>
            <Skeleton h="20px" />
            <Skeleton h="20px" />
            <Skeleton h="20px" />
          </Stack>
        )}
        {initData && (
          <>
            <Text fontWeight="medium" mb="2">
              {initData.profile.email}
            </Text>
            <MenuOptionGroup value={initData.currentTeam.id}>
              {initData.teams.map((team) => (
                <MenuItemOption
                  icon={<Avatar name={team.name} size="xs" />}
                  value={team.id}
                  key={team.id}
                  fontWeight="semibold"
                  rounded="md"
                  onClick={() => switchTeamWithId(team.id)}>
                  {team.name}
                </MenuItemOption>
              ))}
            </MenuOptionGroup>
          </>
        )}

        <MenuDivider />
        <MenuItem rounded="md" onClick={() => push(PATH.PROFILE)}>
          {t('menuItems.profile')}
        </MenuItem>
        <MenuItem rounded="md">{t('menuItems.admin')}</MenuItem>
        <MenuDivider />
        <MenuItem rounded="md" onClick={() => signOut()}>
          {t('menuItems.logout')}
        </MenuItem>
      </MenuList>
    </Menu>
  );
};
