export enum PATH {
  AUTH_SIGN_IN = '/auth/sign-in',
  AUTH_SIGN_UP = '/auth/sign-up',
  AUTH_INVITE = '/auth/invite',
  AUTH_SIGN_UP_INFO = '/auth/sign-up/info',
  DASHBOARD = '/dashboard',
  APPS = '/apps',
  GET_STARTED = '/get-started',
  BILLING = '/billing',
  CUSTOMERS = '/customers',
  CUSTOMERS_ACCOUNT = '/customers/accounts',
  SETTINGS = '/settings',
  AUDIT_LOGS = '/audit-logs',
  DOCUMENT = 'https://docs.walawong.com',
  SUPPORT = '/support',
  PROFILE = '/account/profile',
  PLANS = '/plans',
}
