// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const staging = {
  apiKey: 'AIzaSyBI4--wssiUnJd5GsxuEL40ki65M2QVthI',
  authDomain: 'walawong-staging.firebaseapp.com',
  projectId: 'walawong-staging',
  storageBucket: 'walawong-staging.appspot.com',
  messagingSenderId: '470910336691',
  appId: '1:470910336691:web:2fa50d2702b0d243967ac6',
  measurementId: 'G-VL0BB0T9RB',
};

const production = {
  apiKey: 'AIzaSyC7t5N0ddJGBCcYa6BBILVWdVdVhYw8AUU',
  authDomain: 'walawong-production.firebaseapp.com',
  projectId: 'walawong-production',
  storageBucket: 'walawong-production.appspot.com',
  messagingSenderId: '621084353975',
  appId: '1:621084353975:web:53026a3a23aefa0156b432',
  measurementId: 'G-6F1K1DR66E',
};

const configs = process.env.REACT_APP_ENV === 'production' ? production : staging;

export default configs;
