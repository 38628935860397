import {
  createClient,
  dedupExchange,
  fetchExchange,
  Exchange,
  makeOperation,
  subscriptionExchange,
} from 'urql';
import { retryExchange } from '@urql/exchange-retry';
import { refocusExchange } from '@urql/exchange-refocus';
import { cacheExchange } from '@urql/exchange-graphcache';
import { SubscriptionClient } from 'subscriptions-transport-ws';
import { requestPolicyExchange } from '@urql/exchange-request-policy';
import { getAuth } from 'firebase/auth';
import { authExchange } from '@urql/exchange-auth';
import schema from './user/schema.json';
import { getUserClaimsFromToken } from 'utils/TokenClaim';

const HASURA_GRAPHQL_ENDPOINT = process.env.REACT_APP_HASURA_GRAPHQL_ENDPOINT || '';

type AuthState = {
  token: string;
};

export const makeClient = (role?: string) => {
  console.log('makeClient role ', role);
  const firebaseAuth = getAuth();
  const subscriptionClient = new SubscriptionClient(
    HASURA_GRAPHQL_ENDPOINT.replace('https', 'wss'),
    {
      reconnect: true,
      lazy: true,
    }
  );

  const exchanges: Exchange[] = [
    dedupExchange,
    requestPolicyExchange({}),
    refocusExchange(),
    cacheExchange({
      // @ts-ignore
      schema,
      keys: {
        MeOutput: (data) => (data as any).user_id,
        provider_credentials: (data) => (data as any).provider_name,
        app_accounts_aggregate_fields: () => null,
        app_accounts_aggregate: () => null,
        InviteCodeOutput: () => null,
      },
    }),
    retryExchange({
      initialDelayMs: 1000,
      maxDelayMs: 15000,
      randomDelay: true,
      maxNumberAttempts: 2,
      retryIf: (error) => {
        if (error && error.networkError) {
          return true;
        }
        return false;
      },
    }),
    authExchange<AuthState>({
      addAuthToOperation: ({ authState, operation }) => {
        if (!authState || !authState.token) {
          return operation;
        }

        const fetchOptions =
          typeof operation.context.fetchOptions === 'function'
            ? operation.context.fetchOptions()
            : operation.context.fetchOptions || {};

        return makeOperation(operation.kind, operation, {
          ...operation.context,
          fetchOptions: {
            ...fetchOptions,
            headers: {
              ...fetchOptions.headers,
              Authorization: `Bearer ${authState.token}`,
            },
          },
        });
      },
      willAuthError: ({ operation, authState }) => {
        if (!authState) {
          return !(
            operation.kind === 'mutation' &&
            operation.query.definitions.some((definition) => {
              return (
                definition.kind === 'OperationDefinition' &&
                definition.selectionSet.selections.some((node) => {
                  return node.kind === 'Field' && node.name.value === 'signUp';
                })
              );
            })
          );
        }
        return false;
      },
      didAuthError: ({ error }) => {
        return error.graphQLErrors.some((e) => e.extensions?.code === 'invalid-jwt');
      },
      getAuth: async ({ authState, mutate }) => {
        if (!authState) {
          const tokenResult = await firebaseAuth.currentUser?.getIdTokenResult();
          if (tokenResult) {
            const claim = getUserClaimsFromToken(tokenResult);
            return claim ? { token: claim.token } : null;
          }
          return null;
        }

        const tokenResult = await firebaseAuth.currentUser?.getIdTokenResult(true);

        if (tokenResult) {
          if (tokenResult) {
            const claim = getUserClaimsFromToken(tokenResult);
            if (claim) {
              return { token: claim.token };
            }
          }
        }
        firebaseAuth.signOut();
        return null;
      },
    }),
    fetchExchange,
    subscriptionExchange({
      forwardSubscription: (operation) => subscriptionClient.request(operation),
    }),
  ];

  return createClient({
    url: HASURA_GRAPHQL_ENDPOINT,
    suspense: false,
    exchanges,
  });
};
