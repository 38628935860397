import type { IdTokenResult } from 'firebase/auth';
import type { UserClaims } from '../AppContext';

export const getUserClaimsFromToken = (idTokenResult: IdTokenResult) => {
  const hasuraClaim = idTokenResult.claims['https://hasura.io/jwt/claims'];
  if (hasuraClaim) {
    const claims = hasuraClaim as Record<string, string | string[]>;
    const userClaims: UserClaims = {
      id: claims['x-hasura-user-id'] as string,
      teamId: claims['x-hasura-team-id'] as string,
      permissions: claims['x-hasura-allowed-roles'] as string[],
      token: idTokenResult.token,
    };
    return userClaims;
  }
  return undefined;
};
