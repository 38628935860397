import { RouteConfig } from 'react-router-config';
import { Redirect } from 'react-router-dom';
import { lazy } from 'react';
import { PATH } from 'Paths';

// Layouts
//import { FullWidthCenterLayout } from 'components/layouts/FullWidthCenterLayout';
import { DefaultLayout } from 'components/layouts/DefaultLayout';
import { DashboardLayout } from 'components/layouts/DashboardLayout';
// import NotFoundPage from ''

export const routes: RouteConfig[] = [
  {
    path: '/auth',
    component: DefaultLayout,
    routes: [
      {
        path: PATH.AUTH_SIGN_IN,
        exact: true,
        component: lazy(() => import('./views/SigInPage')),
      },
      {
        path: PATH.AUTH_SIGN_UP,
        exact: true,
        component: lazy(() => import('./views/SignUpPage')),
      },
      {
        path: `${PATH.AUTH_INVITE}/:code`,
        exact: true,
        component: lazy(() => import('./views/InvitePage')),
      },
      {
        path: '*',
        exact: true,
        component: () => <Redirect to={PATH.AUTH_SIGN_IN} />,
      },
    ],
  },
  {
    path: '/',
    component: DashboardLayout,
    routes: [
      {
        path: '/',
        exact: true,
        component: () => <Redirect to={PATH.GET_STARTED} />,
      },
      {
        path: PATH.DASHBOARD,
        exact: true,
        component: lazy(() => import('./views/DashboardPage')),
      },
      {
        path: `${PATH.APPS}`,
        exact: true,
        component: lazy(() => import('./views/Apps/AppListPage')),
      },
      {
        path: `${PATH.APPS}/new`,
        exact: true,
        component: lazy(() => import('./views/Apps/NewAppPage')),
      },
      {
        path: `${PATH.APPS}/:id`,
        exact: true,
        component: lazy(() => import('./views/Apps/EditAppPage')),
      },
      {
        path: `${PATH.GET_STARTED}`,
        exact: true,
        component: lazy(() => import('./views/GetStarted')),
      },
      {
        path: `${PATH.CUSTOMERS}`,
        exact: true,
        component: () => <Redirect to={PATH.CUSTOMERS_ACCOUNT} />,
      },
      {
        path: `${PATH.CUSTOMERS_ACCOUNT}`,
        exact: true,
        component: lazy(() => import('./views/Customers/Accounts/AccountsPage')),
      },
      {
        path: `${PATH.SETTINGS}`,
        exact: true,
        component: lazy(() => import('./views/Settings/SettingsPage')),
      },
      {
        path: `${PATH.BILLING}`,
        exact: true,
        component: lazy(() => import('./views/Billing/BillingPage')),
      },
      {
        path: `${PATH.SUPPORT}`,
        exact: true,
        component: lazy(() => import('./views/HelpAndSupportPage')),
      },
      {
        path: `${PATH.PROFILE}`,
        exact: true,
        component: lazy(() => import('./views/Profile/ProfilePage')),
      },
      {
        path: `${PATH.PLANS}`,
        exact: true,
        component: lazy(() => import('./views/Plans/PlanListPage')),
      },
      {
        path: '*',
        component: () => <Redirect to={PATH.DASHBOARD} />,
      },
    ],
  },
  {
    path: '*',
    exact: true,
    component: DefaultLayout,
    router: [{ component: lazy(() => import('./views/NotFoundPage')) }],
  },
];
