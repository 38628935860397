import { extendTheme, withDefaultColorScheme } from '@chakra-ui/react';
export const customTheme = extendTheme(
  {
    config: {
      cssVarPrefix: 'wl',
    },
    colors: {
      brand: {
        100: '#DDD6FC',
        200: '#BAAEF9',
        300: '#9383EF',
        400: '#7261E0',
        500: '#4532CC',
        600: '#3324AF',
        700: '#251992',
      },
    },
    components: {
      Alert: {
        defaultProps: {
          colorScheme: 'brand',
        },
      },
    },
  },
  withDefaultColorScheme({ colorScheme: 'brand' })
);
