import { useEffect, useCallback, createContext, useState, FC, useMemo } from 'react';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { Provider } from 'urql';
import { makeClient } from 'client/configure';
import { getUserClaimsFromToken } from 'utils/TokenClaim';
import { Team_Roles_Enum, UserFragment } from 'client/user';
import LoadInitData from './LoadInitData';

export enum ROLES {
  ADMIN = 'admin',
  MANAGER = 'manager',
  USER = 'user',
  PUBLIC = 'public',
}

export type Team = {
  id: string;
  name: string;
  role: Team_Roles_Enum;
  is_trial: boolean;
  is_subscribed: boolean;
  avatarPath?: string;
  joined_at: string;
};

export type InitData = {
  currentTeam: Team;
  teams: Team[];
  profile: UserFragment;
};

export type UserClaims = {
  id: string;
  teamId: string;
  token: string;
  permissions: string[];
};

type ContextValues = {
  isFirebaseInit: boolean;
  isSignedIn: boolean;
  userClaims?: UserClaims;
  initData?: InitData;
  signOut: () => Promise<void>;
  setUserClaims: (userClaims?: UserClaims) => void;
  setInitData: (initData?: InitData) => void;
};

export const AppContext = createContext<ContextValues>({
  isFirebaseInit: false,
  isSignedIn: false,
  signOut: async () => {},
  setUserClaims: () => {},
  setInitData: () => {},
});

export const AppProvider: FC = ({ children }) => {
  const [isFirebaseInit, setFirebaseInit] = useState(false);
  const [userClaims, setUserClaims] = useState<UserClaims>();
  const [initData, setInitData] = useState<InitData>();

  const auth = getAuth();

  const client = useMemo(() => {
    if (userClaims) {
      return makeClient();
    } else {
      return makeClient();
    }
  }, [userClaims]);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      console.log('onAuthStateChanged user is ', !!user);
      if (user) {
        const idTokenResult = await user.getIdTokenResult();
        console.log('token', idTokenResult.token);
        const userClaims = getUserClaimsFromToken(idTokenResult);
        setUserClaims(userClaims);
      } else {
        setUserClaims(undefined);
      }
      setFirebaseInit(true);
    });
    return () => {
      unsubscribe();
    };
  }, [auth]);

  const signOut = useCallback(async () => {
    if (auth.currentUser) {
      await auth.signOut();
    }
  }, [auth]);

  return (
    <AppContext.Provider
      value={{
        isFirebaseInit,
        isSignedIn: !!userClaims,
        userClaims,
        signOut,
        setUserClaims,
        setInitData,
        initData,
      }}>
      <Provider value={client}>
        <LoadInitData>{children}</LoadInitData>
      </Provider>
    </AppContext.Provider>
  );
};
