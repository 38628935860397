import {
  Box,
  Flex,
  FlexProps,
  HStack,
  useMenuButton,
  useColorModeValue,
  Skeleton,
  Avatar,
} from '@chakra-ui/react';
import { FC } from 'react';
import { HiSelector } from 'react-icons/hi';
import { useAuth } from 'hooks/useAuth';
import { getSpacePath } from 'utils/ImageUtl';

export const AccountSwitcherButton: FC<FlexProps> = (props) => {
  const buttonProps = useMenuButton(props);
  const { isFirebaseInit, initData } = useAuth();

  return (
    <Flex
      as="button"
      {...buttonProps}
      w="full"
      display="flex"
      alignItems="center"
      rounded="lg"
      bg={useColorModeValue('gray.100', 'gray.700')}
      px="3"
      py="2"
      fontSize="sm"
      userSelect="none"
      cursor="pointer"
      outline="0"
      transition="all 0.2s"
      _active={{ bg: useColorModeValue('gray.300', 'gray.600') }}
      _focus={{ shadow: 'outline' }}
      disabled={!isFirebaseInit || !initData}>
      <HStack flex="1" spacing="3">
        <Avatar
          name={initData?.currentTeam.name}
          size="sm"
          src={
            initData?.currentTeam.avatarPath
              ? getSpacePath(initData?.currentTeam.avatarPath)
              : undefined
          }
        />
        <Box textAlign="start">
          <Box isTruncated fontWeight="semibold">
            {initData?.currentTeam.name || <Skeleton h="3" />}
          </Box>
          <Box fontSize="xs" color={useColorModeValue('gray.600', 'gray.300')} noOfLines={1}>
            {initData?.currentTeam.id}
          </Box>
        </Box>
      </HStack>
      <Box fontSize="lg" color="gray.400">
        <HiSelector />
      </Box>
    </Flex>
  );
};
