import { Box, HStack, useColorModeValue, useToken, Link } from '@chakra-ui/react';
import { BsCaretRightFill } from 'react-icons/bs';
import { NavLink } from 'react-router-dom';
import { useCallback, useMemo } from 'react';
import type { SubItem } from './Sidebar';

interface NavItemProps {
  href: string;
  label: string;
  subtle?: boolean;
  active?: boolean;
  icon: React.ReactElement;
  endElement?: React.ReactElement;
  children?: React.ReactNode;
  subItems?: SubItem[];
}

export const NavItem = (props: NavItemProps) => {
  const { subtle, icon, children, label, endElement, href, subItems } = props;
  const colorBg = useColorModeValue('gray.100', 'gray.700');
  const [lightColor, darkColor, brand] = useToken('colors', ['gray.100', 'gray.700', 'brand.500']);
  const isInternal = useMemo(() => !href.startsWith('http'), [href]);
  const backgroundColor = useColorModeValue(lightColor, darkColor);

  const renderSubItem = useCallback(
    (subItem: SubItem) => {
      return (
        <HStack
          key={`sub-nav-${subItem.path}`}
          as={NavLink}
          to={subItem.path}
          w="full"
          px="9"
          py="2"
          cursor="pointer"
          userSelect="none"
          rounded="md"
          transition="all 0.2s"
          activeStyle={{ color: brand, fontWeight: 'bolder' }}
          _hover={{ bg: colorBg }}
          _active={{ bg: colorBg }}>
          <Box flex="1" fontWeight="inherit" color={subtle ? 'gray.400' : undefined}>
            {subItem.label}
          </Box>
        </HStack>
      );
    },
    [brand, colorBg, subtle]
  );

  const renderInternal = useCallback(() => {
    return (
      <>
        <HStack
          as={NavLink}
          to={href}
          w="full"
          px="3"
          py="2"
          cursor="pointer"
          userSelect="none"
          rounded="md"
          transition="all 0.2s"
          bg={undefined}
          activeStyle={{ backgroundColor: backgroundColor }}
          _hover={{ bg: colorBg }}
          _active={{ bg: colorBg }}>
          <Box fontSize="lg" color={'gray.400'}>
            {icon}
          </Box>
          <Box flex="1" fontWeight="inherit" color={subtle ? 'gray.400' : undefined}>
            {label}
          </Box>
          {endElement && !children && <Box>{endElement}</Box>}
          {children && <Box fontSize="xs" flexShrink={0} as={BsCaretRightFill} />}
        </HStack>
        {subItems?.map((subItem) => renderSubItem(subItem))}
      </>
    );
  }, [
    backgroundColor,
    children,
    colorBg,
    endElement,
    href,
    icon,
    label,
    renderSubItem,
    subItems,
    subtle,
  ]);

  const renderExternal = useCallback(() => {
    return (
      <>
        <HStack
          as={Link}
          href={href}
          isExternal
          w="full"
          px="3"
          py="2"
          cursor="pointer"
          userSelect="none"
          rounded="md"
          transition="all 0.2s"
          bg={undefined}
          _hover={{ bg: colorBg }}
          _active={{ bg: colorBg }}>
          <Box fontSize="lg" color={'gray.400'}>
            {icon}
          </Box>
          <Box flex="1" fontWeight="inherit" color={subtle ? 'gray.400' : undefined}>
            {label}
          </Box>
          {endElement && !children && <Box>{endElement}</Box>}
          {children && <Box fontSize="xs" flexShrink={0} as={BsCaretRightFill} />}
        </HStack>
        {subItems?.map((subItem) => renderSubItem(subItem))}
      </>
    );
  }, [children, colorBg, endElement, href, icon, label, renderSubItem, subItems, subtle]);

  return isInternal ? renderInternal() : renderExternal();
};
