import { initializeApp } from 'firebase/app';
import { ChakraProvider, Center, Text } from '@chakra-ui/react';
import { BrowserRouter } from 'react-router-dom';
import { renderRoutes } from 'react-router-config';
import 'i18n/config';
import { routes } from './routes';
import { customTheme } from 'theme';
import { AppProvider } from './AppContext';
import firebaseConfig from 'configs/firebaseConfig';
import { Offline } from 'react-detect-offline';

initializeApp(firebaseConfig);

export const App = () => (
  <ChakraProvider theme={customTheme}>
    <Offline>
      <Center
        position="fixed"
        top="0"
        left="0"
        right="0"
        color="white"
        textAlign="center"
        fontSize="sm"
        fontWeight="semibold">
        <Text
          px={{ base: 4, md: 8 }}
          py={{ base: 0, md: 1 }}
          backgroundColor="red.400"
          rounded="3xl"
          mt="1">
          No internet connection, please check !
        </Text>
      </Center>
    </Offline>
    <BrowserRouter>
      <AppProvider>{renderRoutes(routes)}</AppProvider>
    </BrowserRouter>
  </ChakraProvider>
);
