export const getSpacePath = (path?: string) =>
  path && `${process.env.REACT_APP_FILE_ENDPOINT}/${path}`;

export const dataURLtoBlob = (dataURL: string, filename: string, mimeType: string) => {
  return fetch(dataURL)
    .then(function (res) {
      return res.arrayBuffer();
    })
    .then(function (buf) {
      return new File([buf], filename, { type: mimeType });
    });
};
