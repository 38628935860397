import { Suspense, FC } from 'react';
import { renderRoutes, RouteConfigComponentProps } from 'react-router-config';
import { Box, useColorModeValue, Center } from '@chakra-ui/react';
import { Icon } from 'Icon';

export const DefaultLayout: FC<RouteConfigComponentProps> = ({ route }) => {
  const Loading = () => {
    return (
      <Center w="full" minH="100vh">
        <Icon mt="-10" />
      </Center>
    );
  };
  return (
    <Box bg={useColorModeValue('gray.50', 'inherit')} minH="100vh">
      <Suspense fallback={<Loading />}>{renderRoutes(route?.routes)}</Suspense>
    </Box>
  );
};
