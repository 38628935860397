import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Maybe<T> = T | null | undefined;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  _text: any;
  bigint: any;
  json: any;
  jsonb: any;
  numeric: number;
  timestamptz: string;
  uuid: string;
};

export type AppLiveInput = {
  appId: Scalars['String'];
  isLive: Scalars['Boolean'];
};

/** Boolean expression to compare columns of type "Boolean". All fields are combined with logical 'AND'. */
export type Boolean_Comparison_Exp = {
  _eq?: Maybe<Scalars['Boolean']>;
  _gt?: Maybe<Scalars['Boolean']>;
  _gte?: Maybe<Scalars['Boolean']>;
  _in?: Maybe<Array<Scalars['Boolean']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['Boolean']>;
  _lte?: Maybe<Scalars['Boolean']>;
  _neq?: Maybe<Scalars['Boolean']>;
  _nin?: Maybe<Array<Scalars['Boolean']>>;
};

export enum FileType {
  Icon = 'ICON',
}

/** Boolean expression to compare columns of type "Int". All fields are combined with logical 'AND'. */
export type Int_Comparison_Exp = {
  _eq?: Maybe<Scalars['Int']>;
  _gt?: Maybe<Scalars['Int']>;
  _gte?: Maybe<Scalars['Int']>;
  _in?: Maybe<Array<Scalars['Int']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['Int']>;
  _lte?: Maybe<Scalars['Int']>;
  _neq?: Maybe<Scalars['Int']>;
  _nin?: Maybe<Array<Scalars['Int']>>;
};

export type MemberRoleInput = {
  role_id: Scalars['String'];
  team_member_id: Scalars['uuid'];
};

export type PresignedUrlInput = {
  fileSize: Scalars['Int'];
  fileType: FileType;
};

export type SignUpInput = {
  companyName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  fullName: Scalars['String'];
  phoneNumber?: Maybe<Scalars['String']>;
};

/** Boolean expression to compare columns of type "String". All fields are combined with logical 'AND'. */
export type String_Comparison_Exp = {
  _eq?: Maybe<Scalars['String']>;
  _gt?: Maybe<Scalars['String']>;
  _gte?: Maybe<Scalars['String']>;
  /** does the column match the given case-insensitive pattern */
  _ilike?: Maybe<Scalars['String']>;
  _in?: Maybe<Array<Scalars['String']>>;
  /** does the column match the given POSIX regular expression, case insensitive */
  _iregex?: Maybe<Scalars['String']>;
  _is_null?: Maybe<Scalars['Boolean']>;
  /** does the column match the given pattern */
  _like?: Maybe<Scalars['String']>;
  _lt?: Maybe<Scalars['String']>;
  _lte?: Maybe<Scalars['String']>;
  _neq?: Maybe<Scalars['String']>;
  /** does the column NOT match the given case-insensitive pattern */
  _nilike?: Maybe<Scalars['String']>;
  _nin?: Maybe<Array<Scalars['String']>>;
  /** does the column NOT match the given POSIX regular expression, case insensitive */
  _niregex?: Maybe<Scalars['String']>;
  /** does the column NOT match the given pattern */
  _nlike?: Maybe<Scalars['String']>;
  /** does the column NOT match the given POSIX regular expression, case sensitive */
  _nregex?: Maybe<Scalars['String']>;
  /** does the column NOT match the given SQL regular expression */
  _nsimilar?: Maybe<Scalars['String']>;
  /** does the column match the given POSIX regular expression, case sensitive */
  _regex?: Maybe<Scalars['String']>;
  /** does the column match the given SQL regular expression */
  _similar?: Maybe<Scalars['String']>;
};

/** Boolean expression to compare columns of type "_text". All fields are combined with logical 'AND'. */
export type _Text_Comparison_Exp = {
  _eq?: Maybe<Scalars['_text']>;
  _gt?: Maybe<Scalars['_text']>;
  _gte?: Maybe<Scalars['_text']>;
  _in?: Maybe<Array<Scalars['_text']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['_text']>;
  _lte?: Maybe<Scalars['_text']>;
  _neq?: Maybe<Scalars['_text']>;
  _nin?: Maybe<Array<Scalars['_text']>>;
};

/** Boolean expression to filter rows from the table "accountconnectedstatistics". All fields are combined with a logical 'AND'. */
export type Accountconnectedstatistics_Bool_Exp = {
  _and?: Maybe<Array<Accountconnectedstatistics_Bool_Exp>>;
  _not?: Maybe<Accountconnectedstatistics_Bool_Exp>;
  _or?: Maybe<Array<Accountconnectedstatistics_Bool_Exp>>;
  count?: Maybe<Bigint_Comparison_Exp>;
  provider_name?: Maybe<String_Comparison_Exp>;
  team?: Maybe<Teams_Bool_Exp>;
  team_id?: Maybe<String_Comparison_Exp>;
};

/** Ordering options when selecting data from "accountconnectedstatistics". */
export type Accountconnectedstatistics_Order_By = {
  count?: Maybe<Order_By>;
  provider_name?: Maybe<Order_By>;
  team?: Maybe<Teams_Order_By>;
  team_id?: Maybe<Order_By>;
};

/** select columns of table "accountconnectedstatistics" */
export enum Accountconnectedstatistics_Select_Column {
  /** column name */
  Count = 'count',
  /** column name */
  ProviderName = 'provider_name',
  /** column name */
  TeamId = 'team_id',
}

/** order by aggregate values of table "app_accounts" */
export type App_Accounts_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<App_Accounts_Max_Order_By>;
  min?: Maybe<App_Accounts_Min_Order_By>;
};

/** Boolean expression to filter rows from the table "app_accounts". All fields are combined with a logical 'AND'. */
export type App_Accounts_Bool_Exp = {
  _and?: Maybe<Array<App_Accounts_Bool_Exp>>;
  _not?: Maybe<App_Accounts_Bool_Exp>;
  _or?: Maybe<Array<App_Accounts_Bool_Exp>>;
  app_user?: Maybe<App_Users_Bool_Exp>;
  app_user_id?: Maybe<Uuid_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  is_connected?: Maybe<Boolean_Comparison_Exp>;
  provider_credential?: Maybe<Provider_Credentials_Bool_Exp>;
  provider_credentials_id?: Maybe<Uuid_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** order by max() on columns of table "app_accounts" */
export type App_Accounts_Max_Order_By = {
  app_user_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  provider_credentials_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** order by min() on columns of table "app_accounts" */
export type App_Accounts_Min_Order_By = {
  app_user_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  provider_credentials_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** Ordering options when selecting data from "app_accounts". */
export type App_Accounts_Order_By = {
  app_user?: Maybe<App_Users_Order_By>;
  app_user_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  is_connected?: Maybe<Order_By>;
  provider_credential?: Maybe<Provider_Credentials_Order_By>;
  provider_credentials_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: app_accounts */
export type App_Accounts_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "app_accounts" */
export enum App_Accounts_Select_Column {
  /** column name */
  AppUserId = 'app_user_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  IsConnected = 'is_connected',
  /** column name */
  ProviderCredentialsId = 'provider_credentials_id',
  /** column name */
  UpdatedAt = 'updated_at',
}

/** input type for updating data in table "app_accounts" */
export type App_Accounts_Set_Input = {
  is_connected?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "app_users" */
export type App_Users_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<App_Users_Max_Order_By>;
  min?: Maybe<App_Users_Min_Order_By>;
};

/** Boolean expression to filter rows from the table "app_users". All fields are combined with a logical 'AND'. */
export type App_Users_Bool_Exp = {
  _and?: Maybe<Array<App_Users_Bool_Exp>>;
  _not?: Maybe<App_Users_Bool_Exp>;
  _or?: Maybe<Array<App_Users_Bool_Exp>>;
  app?: Maybe<Apps_Bool_Exp>;
  app_accounts?: Maybe<App_Accounts_Bool_Exp>;
  app_id?: Maybe<Uuid_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  is_deleted?: Maybe<Boolean_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** order by max() on columns of table "app_users" */
export type App_Users_Max_Order_By = {
  app_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** order by min() on columns of table "app_users" */
export type App_Users_Min_Order_By = {
  app_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** Ordering options when selecting data from "app_users". */
export type App_Users_Order_By = {
  app?: Maybe<Apps_Order_By>;
  app_accounts_aggregate?: Maybe<App_Accounts_Aggregate_Order_By>;
  app_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  is_deleted?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** select columns of table "app_users" */
export enum App_Users_Select_Column {
  /** column name */
  AppId = 'app_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  IsDeleted = 'is_deleted',
  /** column name */
  UpdatedAt = 'updated_at',
}

/** order by aggregate values of table "apps" */
export type Apps_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Apps_Max_Order_By>;
  min?: Maybe<Apps_Min_Order_By>;
};

/** Boolean expression to filter rows from the table "apps". All fields are combined with a logical 'AND'. */
export type Apps_Bool_Exp = {
  _and?: Maybe<Array<Apps_Bool_Exp>>;
  _not?: Maybe<Apps_Bool_Exp>;
  _or?: Maybe<Array<Apps_Bool_Exp>>;
  app_users?: Maybe<App_Users_Bool_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  created_by?: Maybe<Users_Bool_Exp>;
  created_by_id?: Maybe<String_Comparison_Exp>;
  description?: Maybe<String_Comparison_Exp>;
  display_name?: Maybe<String_Comparison_Exp>;
  icon?: Maybe<Files_Bool_Exp>;
  icon_id?: Maybe<Uuid_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  is_live?: Maybe<Boolean_Comparison_Exp>;
  name?: Maybe<String_Comparison_Exp>;
  scopes?: Maybe<_Text_Comparison_Exp>;
  secret_key?: Maybe<String_Comparison_Exp>;
  server_ip?: Maybe<String_Comparison_Exp>;
  team?: Maybe<Teams_Bool_Exp>;
  team_id?: Maybe<String_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "apps" */
export enum Apps_Constraint {
  /** unique or primary key constraint */
  AppsPkey = 'apps_pkey',
  /** unique or primary key constraint */
  AppsTeamIdAppNameKey = 'apps_team_id_app_name_key',
}

/** input type for inserting data into table "apps" */
export type Apps_Insert_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  display_name?: Maybe<Scalars['String']>;
  icon_id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  scopes?: Maybe<Scalars['_text']>;
  server_ip?: Maybe<Scalars['String']>;
  team_id?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "apps" */
export type Apps_Max_Order_By = {
  created_at?: Maybe<Order_By>;
  created_by_id?: Maybe<Order_By>;
  description?: Maybe<Order_By>;
  display_name?: Maybe<Order_By>;
  icon_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  secret_key?: Maybe<Order_By>;
  server_ip?: Maybe<Order_By>;
  team_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** order by min() on columns of table "apps" */
export type Apps_Min_Order_By = {
  created_at?: Maybe<Order_By>;
  created_by_id?: Maybe<Order_By>;
  description?: Maybe<Order_By>;
  display_name?: Maybe<Order_By>;
  icon_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  secret_key?: Maybe<Order_By>;
  server_ip?: Maybe<Order_By>;
  team_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** on conflict condition type for table "apps" */
export type Apps_On_Conflict = {
  constraint: Apps_Constraint;
  update_columns?: Array<Apps_Update_Column>;
  where?: Maybe<Apps_Bool_Exp>;
};

/** Ordering options when selecting data from "apps". */
export type Apps_Order_By = {
  app_users_aggregate?: Maybe<App_Users_Aggregate_Order_By>;
  created_at?: Maybe<Order_By>;
  created_by?: Maybe<Users_Order_By>;
  created_by_id?: Maybe<Order_By>;
  description?: Maybe<Order_By>;
  display_name?: Maybe<Order_By>;
  icon?: Maybe<Files_Order_By>;
  icon_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  is_live?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  scopes?: Maybe<Order_By>;
  secret_key?: Maybe<Order_By>;
  server_ip?: Maybe<Order_By>;
  team?: Maybe<Teams_Order_By>;
  team_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: apps */
export type Apps_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "apps" */
export enum Apps_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedById = 'created_by_id',
  /** column name */
  Description = 'description',
  /** column name */
  DisplayName = 'display_name',
  /** column name */
  IconId = 'icon_id',
  /** column name */
  Id = 'id',
  /** column name */
  IsLive = 'is_live',
  /** column name */
  Name = 'name',
  /** column name */
  Scopes = 'scopes',
  /** column name */
  SecretKey = 'secret_key',
  /** column name */
  ServerIp = 'server_ip',
  /** column name */
  TeamId = 'team_id',
  /** column name */
  UpdatedAt = 'updated_at',
}

/** input type for updating data in table "apps" */
export type Apps_Set_Input = {
  description?: Maybe<Scalars['String']>;
  display_name?: Maybe<Scalars['String']>;
  icon_id?: Maybe<Scalars['uuid']>;
  is_deleted?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  scopes?: Maybe<Scalars['_text']>;
  server_ip?: Maybe<Scalars['String']>;
};

/** update columns of table "apps" */
export enum Apps_Update_Column {
  /** column name */
  Description = 'description',
  /** column name */
  DisplayName = 'display_name',
  /** column name */
  IconId = 'icon_id',
  /** column name */
  IsDeleted = 'is_deleted',
  /** column name */
  Name = 'name',
  /** column name */
  Scopes = 'scopes',
  /** column name */
  ServerIp = 'server_ip',
}

/** Boolean expression to compare columns of type "bigint". All fields are combined with logical 'AND'. */
export type Bigint_Comparison_Exp = {
  _eq?: Maybe<Scalars['bigint']>;
  _gt?: Maybe<Scalars['bigint']>;
  _gte?: Maybe<Scalars['bigint']>;
  _in?: Maybe<Array<Scalars['bigint']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['bigint']>;
  _lte?: Maybe<Scalars['bigint']>;
  _neq?: Maybe<Scalars['bigint']>;
  _nin?: Maybe<Array<Scalars['bigint']>>;
};

/** Boolean expression to filter rows from the table "files". All fields are combined with a logical 'AND'. */
export type Files_Bool_Exp = {
  _and?: Maybe<Array<Files_Bool_Exp>>;
  _not?: Maybe<Files_Bool_Exp>;
  _or?: Maybe<Array<Files_Bool_Exp>>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  mime_type?: Maybe<String_Comparison_Exp>;
  path?: Maybe<String_Comparison_Exp>;
  size?: Maybe<Numeric_Comparison_Exp>;
  user?: Maybe<Users_Bool_Exp>;
  user_id?: Maybe<String_Comparison_Exp>;
};

/** Ordering options when selecting data from "files". */
export type Files_Order_By = {
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  mime_type?: Maybe<Order_By>;
  path?: Maybe<Order_By>;
  size?: Maybe<Order_By>;
  user?: Maybe<Users_Order_By>;
  user_id?: Maybe<Order_By>;
};

/** select columns of table "files" */
export enum Files_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  MimeType = 'mime_type',
  /** column name */
  Path = 'path',
  /** column name */
  Size = 'size',
  /** column name */
  UserId = 'user_id',
}

/** Boolean expression to compare columns of type "numeric". All fields are combined with logical 'AND'. */
export type Numeric_Comparison_Exp = {
  _eq?: Maybe<Scalars['numeric']>;
  _gt?: Maybe<Scalars['numeric']>;
  _gte?: Maybe<Scalars['numeric']>;
  _in?: Maybe<Array<Scalars['numeric']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['numeric']>;
  _lte?: Maybe<Scalars['numeric']>;
  _neq?: Maybe<Scalars['numeric']>;
  _nin?: Maybe<Array<Scalars['numeric']>>;
};

/** column ordering options */
export enum Order_By {
  /** in ascending order, nulls last */
  Asc = 'asc',
  /** in ascending order, nulls first */
  AscNullsFirst = 'asc_nulls_first',
  /** in ascending order, nulls last */
  AscNullsLast = 'asc_nulls_last',
  /** in descending order, nulls first */
  Desc = 'desc',
  /** in descending order, nulls first */
  DescNullsFirst = 'desc_nulls_first',
  /** in descending order, nulls last */
  DescNullsLast = 'desc_nulls_last',
}

/** Boolean expression to filter rows from the table "plans". All fields are combined with a logical 'AND'. */
export type Plans_Bool_Exp = {
  _and?: Maybe<Array<Plans_Bool_Exp>>;
  _not?: Maybe<Plans_Bool_Exp>;
  _or?: Maybe<Array<Plans_Bool_Exp>>;
  fee_monthly?: Maybe<Numeric_Comparison_Exp>;
  fee_monthly_accounts_connection?: Maybe<Numeric_Comparison_Exp>;
  free_monthly_accounts?: Maybe<Int_Comparison_Exp>;
  id?: Maybe<String_Comparison_Exp>;
  limit_app?: Maybe<Int_Comparison_Exp>;
  limit_member?: Maybe<Int_Comparison_Exp>;
  name?: Maybe<String_Comparison_Exp>;
  user?: Maybe<Users_Bool_Exp>;
};

/** Ordering options when selecting data from "plans". */
export type Plans_Order_By = {
  fee_monthly?: Maybe<Order_By>;
  fee_monthly_accounts_connection?: Maybe<Order_By>;
  free_monthly_accounts?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  limit_app?: Maybe<Order_By>;
  limit_member?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  user?: Maybe<Users_Order_By>;
};

/** select columns of table "plans" */
export enum Plans_Select_Column {
  /** column name */
  FeeMonthly = 'fee_monthly',
  /** column name */
  FeeMonthlyAccountsConnection = 'fee_monthly_accounts_connection',
  /** column name */
  FreeMonthlyAccounts = 'free_monthly_accounts',
  /** column name */
  Id = 'id',
  /** column name */
  LimitApp = 'limit_app',
  /** column name */
  LimitMember = 'limit_member',
  /** column name */
  Name = 'name',
}

/** Boolean expression to filter rows from the table "provider_auth_types". All fields are combined with a logical 'AND'. */
export type Provider_Auth_Types_Bool_Exp = {
  _and?: Maybe<Array<Provider_Auth_Types_Bool_Exp>>;
  _not?: Maybe<Provider_Auth_Types_Bool_Exp>;
  _or?: Maybe<Array<Provider_Auth_Types_Bool_Exp>>;
  id?: Maybe<String_Comparison_Exp>;
};

export enum Provider_Auth_Types_Enum {
  Address = 'address',
  ApiKey = 'api_key',
  Oauth = 'oauth',
  PrivateKey = 'private_key',
}

/** Boolean expression to compare columns of type "provider_auth_types_enum". All fields are combined with logical 'AND'. */
export type Provider_Auth_Types_Enum_Comparison_Exp = {
  _eq?: Maybe<Provider_Auth_Types_Enum>;
  _in?: Maybe<Array<Provider_Auth_Types_Enum>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _neq?: Maybe<Provider_Auth_Types_Enum>;
  _nin?: Maybe<Array<Provider_Auth_Types_Enum>>;
};

/** Ordering options when selecting data from "provider_auth_types". */
export type Provider_Auth_Types_Order_By = {
  id?: Maybe<Order_By>;
};

/** select columns of table "provider_auth_types" */
export enum Provider_Auth_Types_Select_Column {
  /** column name */
  Id = 'id',
}

/** Boolean expression to filter rows from the table "provider_credentials". All fields are combined with a logical 'AND'. */
export type Provider_Credentials_Bool_Exp = {
  _and?: Maybe<Array<Provider_Credentials_Bool_Exp>>;
  _not?: Maybe<Provider_Credentials_Bool_Exp>;
  _or?: Maybe<Array<Provider_Credentials_Bool_Exp>>;
  app_accounts?: Maybe<App_Accounts_Bool_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  provider?: Maybe<Providers_Bool_Exp>;
  provider_name?: Maybe<String_Comparison_Exp>;
  provider_uid?: Maybe<String_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** Ordering options when selecting data from "provider_credentials". */
export type Provider_Credentials_Order_By = {
  app_accounts_aggregate?: Maybe<App_Accounts_Aggregate_Order_By>;
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  provider?: Maybe<Providers_Order_By>;
  provider_name?: Maybe<Order_By>;
  provider_uid?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** select columns of table "provider_credentials" */
export enum Provider_Credentials_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  ProviderName = 'provider_name',
  /** column name */
  ProviderUid = 'provider_uid',
  /** column name */
  UpdatedAt = 'updated_at',
}

/** Boolean expression to filter rows from the table "provider_types". All fields are combined with a logical 'AND'. */
export type Provider_Types_Bool_Exp = {
  _and?: Maybe<Array<Provider_Types_Bool_Exp>>;
  _not?: Maybe<Provider_Types_Bool_Exp>;
  _or?: Maybe<Array<Provider_Types_Bool_Exp>>;
  id?: Maybe<String_Comparison_Exp>;
};

export enum Provider_Types_Enum {
  Blockchain = 'blockchain',
  Exchange = 'exchange',
  Wallet = 'wallet',
}

/** Boolean expression to compare columns of type "provider_types_enum". All fields are combined with logical 'AND'. */
export type Provider_Types_Enum_Comparison_Exp = {
  _eq?: Maybe<Provider_Types_Enum>;
  _in?: Maybe<Array<Provider_Types_Enum>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _neq?: Maybe<Provider_Types_Enum>;
  _nin?: Maybe<Array<Provider_Types_Enum>>;
};

/** Ordering options when selecting data from "provider_types". */
export type Provider_Types_Order_By = {
  id?: Maybe<Order_By>;
};

/** select columns of table "provider_types" */
export enum Provider_Types_Select_Column {
  /** column name */
  Id = 'id',
}

/** Boolean expression to filter rows from the table "providers". All fields are combined with a logical 'AND'. */
export type Providers_Bool_Exp = {
  _and?: Maybe<Array<Providers_Bool_Exp>>;
  _not?: Maybe<Providers_Bool_Exp>;
  _or?: Maybe<Array<Providers_Bool_Exp>>;
  auth_type_id?: Maybe<Provider_Auth_Types_Enum_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  display_name?: Maybe<String_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  is_activate?: Maybe<Boolean_Comparison_Exp>;
  name?: Maybe<String_Comparison_Exp>;
  type_id?: Maybe<Provider_Types_Enum_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** Ordering options when selecting data from "providers". */
export type Providers_Order_By = {
  auth_type_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  display_name?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  is_activate?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  type_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** select columns of table "providers" */
export enum Providers_Select_Column {
  /** column name */
  AuthTypeId = 'auth_type_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DisplayName = 'display_name',
  /** column name */
  Id = 'id',
  /** column name */
  IsActivate = 'is_activate',
  /** column name */
  Name = 'name',
  /** column name */
  TypeId = 'type_id',
  /** column name */
  UpdatedAt = 'updated_at',
}

/** Boolean expression to filter rows from the table "subscriptions". All fields are combined with a logical 'AND'. */
export type Subscriptions_Bool_Exp = {
  _and?: Maybe<Array<Subscriptions_Bool_Exp>>;
  _not?: Maybe<Subscriptions_Bool_Exp>;
  _or?: Maybe<Array<Subscriptions_Bool_Exp>>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  plan?: Maybe<Plans_Bool_Exp>;
  plan_id?: Maybe<String_Comparison_Exp>;
  subscribe_after_trial?: Maybe<Boolean_Comparison_Exp>;
  subscribed_at?: Maybe<Timestamptz_Comparison_Exp>;
  team?: Maybe<Teams_Bool_Exp>;
  team_id?: Maybe<String_Comparison_Exp>;
  trial_period_end_date?: Maybe<Timestamptz_Comparison_Exp>;
  trial_period_start_date?: Maybe<Timestamptz_Comparison_Exp>;
  unsubscribed_at?: Maybe<Timestamptz_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
  valid_to?: Maybe<Timestamptz_Comparison_Exp>;
};

/** Ordering options when selecting data from "subscriptions". */
export type Subscriptions_Order_By = {
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  plan?: Maybe<Plans_Order_By>;
  plan_id?: Maybe<Order_By>;
  subscribe_after_trial?: Maybe<Order_By>;
  subscribed_at?: Maybe<Order_By>;
  team?: Maybe<Teams_Order_By>;
  team_id?: Maybe<Order_By>;
  trial_period_end_date?: Maybe<Order_By>;
  trial_period_start_date?: Maybe<Order_By>;
  unsubscribed_at?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  valid_to?: Maybe<Order_By>;
};

/** select columns of table "subscriptions" */
export enum Subscriptions_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  PlanId = 'plan_id',
  /** column name */
  SubscribeAfterTrial = 'subscribe_after_trial',
  /** column name */
  SubscribedAt = 'subscribed_at',
  /** column name */
  TeamId = 'team_id',
  /** column name */
  TrialPeriodEndDate = 'trial_period_end_date',
  /** column name */
  TrialPeriodStartDate = 'trial_period_start_date',
  /** column name */
  UnsubscribedAt = 'unsubscribed_at',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  ValidTo = 'valid_to',
}

/** Boolean expression to filter rows from the table "team_member_status". All fields are combined with a logical 'AND'. */
export type Team_Member_Status_Bool_Exp = {
  _and?: Maybe<Array<Team_Member_Status_Bool_Exp>>;
  _not?: Maybe<Team_Member_Status_Bool_Exp>;
  _or?: Maybe<Array<Team_Member_Status_Bool_Exp>>;
  id?: Maybe<String_Comparison_Exp>;
};

export enum Team_Member_Status_Enum {
  Joined = 'JOINED',
  Pending = 'PENDING',
  Rejected = 'REJECTED',
}

/** Boolean expression to compare columns of type "team_member_status_enum". All fields are combined with logical 'AND'. */
export type Team_Member_Status_Enum_Comparison_Exp = {
  _eq?: Maybe<Team_Member_Status_Enum>;
  _in?: Maybe<Array<Team_Member_Status_Enum>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _neq?: Maybe<Team_Member_Status_Enum>;
  _nin?: Maybe<Array<Team_Member_Status_Enum>>;
};

/** Ordering options when selecting data from "team_member_status". */
export type Team_Member_Status_Order_By = {
  id?: Maybe<Order_By>;
};

/** select columns of table "team_member_status" */
export enum Team_Member_Status_Select_Column {
  /** column name */
  Id = 'id',
}

/** order by aggregate values of table "team_members" */
export type Team_Members_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Team_Members_Max_Order_By>;
  min?: Maybe<Team_Members_Min_Order_By>;
};

/** Boolean expression to filter rows from the table "team_members". All fields are combined with a logical 'AND'. */
export type Team_Members_Bool_Exp = {
  _and?: Maybe<Array<Team_Members_Bool_Exp>>;
  _not?: Maybe<Team_Members_Bool_Exp>;
  _or?: Maybe<Array<Team_Members_Bool_Exp>>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  email?: Maybe<String_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  invite_by?: Maybe<Users_Bool_Exp>;
  role?: Maybe<Team_Roles_Bool_Exp>;
  role_id?: Maybe<Team_Roles_Enum_Comparison_Exp>;
  status?: Maybe<Team_Member_Status_Bool_Exp>;
  status_id?: Maybe<Team_Member_Status_Enum_Comparison_Exp>;
  team?: Maybe<Teams_Bool_Exp>;
  team_id?: Maybe<String_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
  user?: Maybe<Users_Bool_Exp>;
  user_id?: Maybe<String_Comparison_Exp>;
};

/** order by max() on columns of table "team_members" */
export type Team_Members_Max_Order_By = {
  created_at?: Maybe<Order_By>;
  email?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  team_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** order by min() on columns of table "team_members" */
export type Team_Members_Min_Order_By = {
  created_at?: Maybe<Order_By>;
  email?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  team_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** Ordering options when selecting data from "team_members". */
export type Team_Members_Order_By = {
  created_at?: Maybe<Order_By>;
  email?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  invite_by?: Maybe<Users_Order_By>;
  role?: Maybe<Team_Roles_Order_By>;
  role_id?: Maybe<Order_By>;
  status?: Maybe<Team_Member_Status_Order_By>;
  status_id?: Maybe<Order_By>;
  team?: Maybe<Teams_Order_By>;
  team_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  user?: Maybe<Users_Order_By>;
  user_id?: Maybe<Order_By>;
};

/** select columns of table "team_members" */
export enum Team_Members_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Email = 'email',
  /** column name */
  Id = 'id',
  /** column name */
  RoleId = 'role_id',
  /** column name */
  StatusId = 'status_id',
  /** column name */
  TeamId = 'team_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id',
}

/** Boolean expression to filter rows from the table "team_roles". All fields are combined with a logical 'AND'. */
export type Team_Roles_Bool_Exp = {
  _and?: Maybe<Array<Team_Roles_Bool_Exp>>;
  _not?: Maybe<Team_Roles_Bool_Exp>;
  _or?: Maybe<Array<Team_Roles_Bool_Exp>>;
  id?: Maybe<String_Comparison_Exp>;
};

export enum Team_Roles_Enum {
  Biller = 'BILLER',
  Member = 'MEMBER',
  Owner = 'OWNER',
}

/** Boolean expression to compare columns of type "team_roles_enum". All fields are combined with logical 'AND'. */
export type Team_Roles_Enum_Comparison_Exp = {
  _eq?: Maybe<Team_Roles_Enum>;
  _in?: Maybe<Array<Team_Roles_Enum>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _neq?: Maybe<Team_Roles_Enum>;
  _nin?: Maybe<Array<Team_Roles_Enum>>;
};

/** Ordering options when selecting data from "team_roles". */
export type Team_Roles_Order_By = {
  id?: Maybe<Order_By>;
};

/** select columns of table "team_roles" */
export enum Team_Roles_Select_Column {
  /** column name */
  Id = 'id',
}

/** Boolean expression to filter rows from the table "team_status". All fields are combined with a logical 'AND'. */
export type Team_Status_Bool_Exp = {
  _and?: Maybe<Array<Team_Status_Bool_Exp>>;
  _not?: Maybe<Team_Status_Bool_Exp>;
  _or?: Maybe<Array<Team_Status_Bool_Exp>>;
  id?: Maybe<String_Comparison_Exp>;
};

export enum Team_Status_Enum {
  Activate = 'ACTIVATE',
  Deactivate = 'DEACTIVATE',
  Deleted = 'DELETED',
}

/** Boolean expression to compare columns of type "team_status_enum". All fields are combined with logical 'AND'. */
export type Team_Status_Enum_Comparison_Exp = {
  _eq?: Maybe<Team_Status_Enum>;
  _in?: Maybe<Array<Team_Status_Enum>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _neq?: Maybe<Team_Status_Enum>;
  _nin?: Maybe<Array<Team_Status_Enum>>;
};

/** Ordering options when selecting data from "team_status". */
export type Team_Status_Order_By = {
  id?: Maybe<Order_By>;
};

/** select columns of table "team_status" */
export enum Team_Status_Select_Column {
  /** column name */
  Id = 'id',
}

/** Boolean expression to filter rows from the table "teams". All fields are combined with a logical 'AND'. */
export type Teams_Bool_Exp = {
  _and?: Maybe<Array<Teams_Bool_Exp>>;
  _not?: Maybe<Teams_Bool_Exp>;
  _or?: Maybe<Array<Teams_Bool_Exp>>;
  apps?: Maybe<Apps_Bool_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  created_by?: Maybe<Users_Bool_Exp>;
  created_by_user_id?: Maybe<String_Comparison_Exp>;
  id?: Maybe<String_Comparison_Exp>;
  is_activation_fee?: Maybe<Boolean_Comparison_Exp>;
  is_subscribed?: Maybe<Boolean_Comparison_Exp>;
  is_trial?: Maybe<Boolean_Comparison_Exp>;
  logo?: Maybe<Files_Bool_Exp>;
  logo_id?: Maybe<Uuid_Comparison_Exp>;
  members?: Maybe<Team_Members_Bool_Exp>;
  name?: Maybe<String_Comparison_Exp>;
  status?: Maybe<Team_Status_Bool_Exp>;
  status_id?: Maybe<Team_Status_Enum_Comparison_Exp>;
  subscription?: Maybe<Subscriptions_Bool_Exp>;
  support_email?: Maybe<String_Comparison_Exp>;
  support_phone_number?: Maybe<String_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** Ordering options when selecting data from "teams". */
export type Teams_Order_By = {
  apps_aggregate?: Maybe<Apps_Aggregate_Order_By>;
  created_at?: Maybe<Order_By>;
  created_by?: Maybe<Users_Order_By>;
  created_by_user_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  is_activation_fee?: Maybe<Order_By>;
  is_subscribed?: Maybe<Order_By>;
  is_trial?: Maybe<Order_By>;
  logo?: Maybe<Files_Order_By>;
  logo_id?: Maybe<Order_By>;
  members_aggregate?: Maybe<Team_Members_Aggregate_Order_By>;
  name?: Maybe<Order_By>;
  status?: Maybe<Team_Status_Order_By>;
  status_id?: Maybe<Order_By>;
  subscription?: Maybe<Subscriptions_Order_By>;
  support_email?: Maybe<Order_By>;
  support_phone_number?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: teams */
export type Teams_Pk_Columns_Input = {
  id: Scalars['String'];
};

/** select columns of table "teams" */
export enum Teams_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedByUserId = 'created_by_user_id',
  /** column name */
  Id = 'id',
  /** column name */
  IsActivationFee = 'is_activation_fee',
  /** column name */
  LogoId = 'logo_id',
  /** column name */
  Name = 'name',
  /** column name */
  StatusId = 'status_id',
  /** column name */
  SupportEmail = 'support_email',
  /** column name */
  SupportPhoneNumber = 'support_phone_number',
  /** column name */
  UpdatedAt = 'updated_at',
}

/** input type for updating data in table "teams" */
export type Teams_Set_Input = {
  logo_id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  support_email?: Maybe<Scalars['String']>;
  support_phone_number?: Maybe<Scalars['String']>;
};

/** Boolean expression to compare columns of type "timestamptz". All fields are combined with logical 'AND'. */
export type Timestamptz_Comparison_Exp = {
  _eq?: Maybe<Scalars['timestamptz']>;
  _gt?: Maybe<Scalars['timestamptz']>;
  _gte?: Maybe<Scalars['timestamptz']>;
  _in?: Maybe<Array<Scalars['timestamptz']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['timestamptz']>;
  _lte?: Maybe<Scalars['timestamptz']>;
  _neq?: Maybe<Scalars['timestamptz']>;
  _nin?: Maybe<Array<Scalars['timestamptz']>>;
};

/** Boolean expression to filter rows from the table "user_roles". All fields are combined with a logical 'AND'. */
export type User_Roles_Bool_Exp = {
  _and?: Maybe<Array<User_Roles_Bool_Exp>>;
  _not?: Maybe<User_Roles_Bool_Exp>;
  _or?: Maybe<Array<User_Roles_Bool_Exp>>;
  id?: Maybe<String_Comparison_Exp>;
};

export enum User_Roles_Enum {
  Manager = 'manager',
  Staff = 'staff',
  User = 'user',
}

/** Boolean expression to compare columns of type "user_roles_enum". All fields are combined with logical 'AND'. */
export type User_Roles_Enum_Comparison_Exp = {
  _eq?: Maybe<User_Roles_Enum>;
  _in?: Maybe<Array<User_Roles_Enum>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _neq?: Maybe<User_Roles_Enum>;
  _nin?: Maybe<Array<User_Roles_Enum>>;
};

/** Ordering options when selecting data from "user_roles". */
export type User_Roles_Order_By = {
  id?: Maybe<Order_By>;
};

/** select columns of table "user_roles" */
export enum User_Roles_Select_Column {
  /** column name */
  Id = 'id',
}

/** Boolean expression to filter rows from the table "users". All fields are combined with a logical 'AND'. */
export type Users_Bool_Exp = {
  _and?: Maybe<Array<Users_Bool_Exp>>;
  _not?: Maybe<Users_Bool_Exp>;
  _or?: Maybe<Array<Users_Bool_Exp>>;
  avatar?: Maybe<Files_Bool_Exp>;
  avatar_id?: Maybe<Uuid_Comparison_Exp>;
  company_name?: Maybe<String_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  email?: Maybe<String_Comparison_Exp>;
  full_name?: Maybe<String_Comparison_Exp>;
  id?: Maybe<String_Comparison_Exp>;
  phone_number?: Maybe<String_Comparison_Exp>;
  provider?: Maybe<String_Comparison_Exp>;
  role?: Maybe<User_Roles_Bool_Exp>;
  role_id?: Maybe<User_Roles_Enum_Comparison_Exp>;
  team_members?: Maybe<Team_Members_Bool_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** Ordering options when selecting data from "users". */
export type Users_Order_By = {
  avatar?: Maybe<Files_Order_By>;
  avatar_id?: Maybe<Order_By>;
  company_name?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  email?: Maybe<Order_By>;
  full_name?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  phone_number?: Maybe<Order_By>;
  provider?: Maybe<Order_By>;
  role?: Maybe<User_Roles_Order_By>;
  role_id?: Maybe<Order_By>;
  team_members_aggregate?: Maybe<Team_Members_Aggregate_Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: users */
export type Users_Pk_Columns_Input = {
  id: Scalars['String'];
};

/** select columns of table "users" */
export enum Users_Select_Column {
  /** column name */
  AvatarId = 'avatar_id',
  /** column name */
  CompanyName = 'company_name',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Email = 'email',
  /** column name */
  FullName = 'full_name',
  /** column name */
  Id = 'id',
  /** column name */
  PhoneNumber = 'phone_number',
  /** column name */
  Provider = 'provider',
  /** column name */
  RoleId = 'role_id',
  /** column name */
  UpdatedAt = 'updated_at',
}

/** input type for updating data in table "users" */
export type Users_Set_Input = {
  avatar_id?: Maybe<Scalars['uuid']>;
  company_name?: Maybe<Scalars['String']>;
  full_name?: Maybe<Scalars['String']>;
  phone_number?: Maybe<Scalars['String']>;
};

/** Boolean expression to compare columns of type "uuid". All fields are combined with logical 'AND'. */
export type Uuid_Comparison_Exp = {
  _eq?: Maybe<Scalars['uuid']>;
  _gt?: Maybe<Scalars['uuid']>;
  _gte?: Maybe<Scalars['uuid']>;
  _in?: Maybe<Array<Scalars['uuid']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['uuid']>;
  _lte?: Maybe<Scalars['uuid']>;
  _neq?: Maybe<Scalars['uuid']>;
  _nin?: Maybe<Array<Scalars['uuid']>>;
};

export type AppFragment = {
  __typename?: 'apps';
  id: string;
  display_name: string;
  description?: string | null | undefined;
  created_at: string;
  is_live: boolean;
  name: string;
  scopes: any;
  secret_key: string;
  server_ip?: string | null | undefined;
  team_id: string;
  updated_at: string;
  icon?: ({ __typename?: 'files' } & ImageFragment) | null | undefined;
};

export type AppAccountFragment = {
  __typename?: 'app_accounts';
  id: string;
  is_connected: boolean;
  created_at: string;
  updated_at: string;
  provider_credential: { __typename?: 'provider_credentials'; provider_name: string };
  app_user: {
    __typename?: 'app_users';
    id: string;
    app: {
      __typename?: 'apps';
      id: string;
      display_name: string;
      icon?: ({ __typename?: 'files' } & ImageFragment) | null | undefined;
    };
  };
};

export type ImageFragment = { __typename?: 'files'; id: string; mime_type: string; path: string };

export type PlanFragment = {
  __typename?: 'plans';
  fee_monthly: number;
  fee_monthly_accounts_connection: number;
  free_monthly_accounts: number;
  id: string;
  limit_app: number;
  name: string;
  limit_member: number;
};

export type ProviderFragment = {
  __typename?: 'providers';
  id: string;
  name: string;
  display_name: string;
  type_id: Provider_Types_Enum;
  auth_type_id: Provider_Auth_Types_Enum;
  is_activate: boolean;
};

export type SubscriptionFragment = {
  __typename?: 'subscriptions';
  id: string;
  plan_id: string;
  subscribe_after_trial: boolean;
  subscribed_at?: string | null | undefined;
  trial_period_end_date?: string | null | undefined;
  trial_period_start_date?: string | null | undefined;
  team_id: string;
  unsubscribed_at?: string | null | undefined;
  updated_at: string;
  valid_to?: string | null | undefined;
  plan: { __typename?: 'plans' } & PlanFragment;
};

export type TeamFragment = {
  __typename?: 'teams';
  id: string;
  name: string;
  created_at?: string | null | undefined;
  updated_at?: string | null | undefined;
  is_activation_fee: boolean;
  is_subscribed?: boolean | null | undefined;
  is_trial?: boolean | null | undefined;
  support_email: string;
  support_phone_number?: string | null | undefined;
  status_id: Team_Status_Enum;
  logo?: ({ __typename?: 'files' } & ImageFragment) | null | undefined;
};

export type TeamMemberFragment = {
  __typename?: 'team_members';
  id: string;
  email: string;
  role_id: Team_Roles_Enum;
  status_id?: Team_Member_Status_Enum | null | undefined;
  user?:
    | {
        __typename?: 'users';
        id: string;
        full_name: string;
        provider?: string | null | undefined;
        avatar?: ({ __typename?: 'files' } & ImageFragment) | null | undefined;
      }
    | null
    | undefined;
};

export type UserFragment = {
  __typename?: 'users';
  id: string;
  full_name: string;
  company_name: string;
  email: string;
  created_at: string;
  phone_number?: string | null | undefined;
  role_id: User_Roles_Enum;
  updated_at: string;
  avatar?: ({ __typename?: 'files' } & ImageFragment) | null | undefined;
};

export type ChangeMemberRoleMutationVariables = Exact<{
  arg: MemberRoleInput;
}>;

export type ChangeMemberRoleMutation = {
  __typename?: 'mutation_root';
  changeMemberRole?:
    | {
        __typename?: 'MemberRoleOutput';
        team_member_id: string;
        team_member: { __typename?: 'team_members' } & TeamMemberFragment;
      }
    | null
    | undefined;
};

export type CreateNewAppMutationVariables = Exact<{
  object: Apps_Insert_Input;
}>;

export type CreateNewAppMutation = {
  __typename?: 'mutation_root';
  insert_apps_one?: ({ __typename?: 'apps' } & AppFragment) | null | undefined;
};

export type CreatePresignedUrlMutationVariables = Exact<{
  fileSize: Scalars['Int'];
}>;

export type CreatePresignedUrlMutation = {
  __typename?: 'mutation_root';
  createPresignedUrl?:
    | {
        __typename?: 'PresignedUrlOutput';
        file_id: string;
        postURL: string;
        formData: any;
        file: { __typename?: 'files' } & ImageFragment;
      }
    | null
    | undefined;
};

export type DeleteAppMutationVariables = Exact<{
  appId: Scalars['uuid'];
}>;

export type DeleteAppMutation = {
  __typename?: 'mutation_root';
  update_apps_by_pk?: ({ __typename?: 'apps' } & AppFragment) | null | undefined;
};

export type DeleteTeamMemberMutationVariables = Exact<{
  team_member_id: Scalars['uuid'];
}>;

export type DeleteTeamMemberMutation = {
  __typename?: 'mutation_root';
  deleteTeamMember?:
    | { __typename?: 'DeleteTeamMemberOutput'; is_deleted: boolean }
    | null
    | undefined;
};

export type ReSendTeamInviteMutationVariables = Exact<{
  team_member_id: Scalars['uuid'];
}>;

export type ReSendTeamInviteMutation = { __typename?: 'mutation_root'; reSendTeamInvite: string };

export type ReplyInviteMutationVariables = Exact<{
  code: Scalars['String'];
  isAgree: Scalars['Boolean'];
}>;

export type ReplyInviteMutation = {
  __typename?: 'mutation_root';
  replyInvite?: { __typename?: 'ReplyInviteOutput'; team_member_id: string } | null | undefined;
};

export type ResetSecretKeyMutationVariables = Exact<{
  appId: Scalars['uuid'];
}>;

export type ResetSecretKeyMutation = {
  __typename?: 'mutation_root';
  resetSecretKey?:
    | {
        __typename?: 'ResetSecretKeyOutput';
        app_id: string;
        app: { __typename?: 'apps' } & AppFragment;
      }
    | null
    | undefined;
};

export type SendTeamInviteMutationVariables = Exact<{
  email: Scalars['String'];
  teamId: Scalars['String'];
}>;

export type SendTeamInviteMutation = {
  __typename?: 'mutation_root';
  sendTeamInvite: {
    __typename?: 'InviteOutput';
    team_member_id: string;
    member: { __typename?: 'team_members' } & TeamMemberFragment;
  };
};

export type SignUpMutationVariables = Exact<{
  token: Scalars['String'];
  data: SignUpInput;
}>;

export type SignUpMutation = {
  __typename?: 'mutation_root';
  signUp?: { __typename?: 'SignUpOutput'; userId: string } | null | undefined;
};

export type SwitchAccountConnectStatusMutationVariables = Exact<{
  is_connected?: Maybe<Scalars['Boolean']>;
  id: Scalars['uuid'];
}>;

export type SwitchAccountConnectStatusMutation = {
  __typename?: 'mutation_root';
  update_app_accounts_by_pk?:
    | ({ __typename?: 'app_accounts' } & AppAccountFragment)
    | null
    | undefined;
};

export type SwitchAppLiveMutationVariables = Exact<{
  arg: AppLiveInput;
}>;

export type SwitchAppLiveMutation = {
  __typename?: 'mutation_root';
  switchAppLive: {
    __typename?: 'AppLiveOutput';
    app_id: string;
    app: { __typename?: 'apps' } & AppFragment;
  };
};

export type UpdateAppMutationVariables = Exact<{
  appId: Scalars['uuid'];
  vars?: Maybe<Apps_Set_Input>;
}>;

export type UpdateAppMutation = {
  __typename?: 'mutation_root';
  update_apps_by_pk?: ({ __typename?: 'apps' } & AppFragment) | null | undefined;
};

export type UpdateTeamInfoMutationVariables = Exact<{
  teamId: Scalars['String'];
  _set?: Maybe<Teams_Set_Input>;
}>;

export type UpdateTeamInfoMutation = {
  __typename?: 'mutation_root';
  update_teams_by_pk?: ({ __typename?: 'teams' } & TeamFragment) | null | undefined;
};

export type UpdateUserInfoMutationVariables = Exact<{
  userId: Scalars['String'];
  _set?: Maybe<Users_Set_Input>;
}>;

export type UpdateUserInfoMutation = {
  __typename?: 'mutation_root';
  user?: ({ __typename?: 'users' } & UserFragment) | null | undefined;
};

export type GetAccountConnectedStatisticsQueryVariables = Exact<{
  teamId: Scalars['String'];
}>;

export type GetAccountConnectedStatisticsQuery = {
  __typename?: 'query_root';
  statistics: Array<{
    __typename?: 'accountconnectedstatistics';
    count?: any | null | undefined;
    provider_name?: string | null | undefined;
    team_id?: string | null | undefined;
  }>;
};

export type GetAppQueryVariables = Exact<{
  id: Scalars['uuid'];
}>;

export type GetAppQuery = {
  __typename?: 'query_root';
  app?: ({ __typename?: 'apps' } & AppFragment) | null | undefined;
};

export type GetAppsQueryVariables = Exact<{
  teamId: Scalars['String'];
}>;

export type GetAppsQuery = {
  __typename?: 'query_root';
  apps: Array<{ __typename?: 'apps' } & AppFragment>;
};

export type GetProvidersQueryVariables = Exact<{ [key: string]: never }>;

export type GetProvidersQuery = {
  __typename?: 'query_root';
  providers: Array<{ __typename?: 'providers' } & ProviderFragment>;
};

export type GetTeamsQueryVariables = Exact<{
  user_id: Scalars['String'];
}>;

export type GetTeamsQuery = {
  __typename?: 'query_root';
  teams: Array<{
    __typename?: 'teams';
    id: string;
    name: string;
    members: Array<{
      __typename?: 'team_members';
      role_id: Team_Roles_Enum;
      user_id?: string | null | undefined;
    }>;
  }>;
};

export type GetInitDataQueryVariables = Exact<{
  user_id: Scalars['String'];
}>;

export type GetInitDataQuery = {
  __typename?: 'query_root';
  profile?: ({ __typename?: 'users' } & UserFragment) | null | undefined;
  teams: Array<{
    __typename?: 'teams';
    id: string;
    name: string;
    is_trial?: boolean | null | undefined;
    is_subscribed?: boolean | null | undefined;
    logo_id?: string | null | undefined;
    members: Array<{
      __typename?: 'team_members';
      id: string;
      role_id: Team_Roles_Enum;
      user_id?: string | null | undefined;
      created_at: string;
    }>;
    logo?: ({ __typename?: 'files' } & ImageFragment) | null | undefined;
  }>;
};

export type GetAccountConnectStatisticQueryVariables = Exact<{
  yesterday: Scalars['timestamptz'];
  startThisMonth: Scalars['timestamptz'];
  startLastMonth: Scalars['timestamptz'];
  startThisWeek: Scalars['timestamptz'];
  startLastWeek: Scalars['timestamptz'];
  teamId: Scalars['String'];
}>;

export type GetAccountConnectStatisticQuery = {
  __typename?: 'query_root';
  yesterdayTotal: {
    __typename?: 'app_accounts_aggregate';
    account?: { __typename?: 'app_accounts_aggregate_fields'; count: number } | null | undefined;
  };
  todayTotal: {
    __typename?: 'app_accounts_aggregate';
    account?: { __typename?: 'app_accounts_aggregate_fields'; count: number } | null | undefined;
  };
  thisMonthTotal: {
    __typename?: 'app_accounts_aggregate';
    account?: { __typename?: 'app_accounts_aggregate_fields'; count: number } | null | undefined;
  };
  lastMonthTotal: {
    __typename?: 'app_accounts_aggregate';
    account?: { __typename?: 'app_accounts_aggregate_fields'; count: number } | null | undefined;
  };
  thisWeekTotal: {
    __typename?: 'app_accounts_aggregate';
    account?: { __typename?: 'app_accounts_aggregate_fields'; count: number } | null | undefined;
  };
  lastLastWeekTotal: {
    __typename?: 'app_accounts_aggregate';
    account?: { __typename?: 'app_accounts_aggregate_fields'; count: number } | null | undefined;
  };
};

export type GetConnectedAccountsQueryVariables = Exact<{
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  teamId: Scalars['String'];
  appIdExp?: Maybe<Uuid_Comparison_Exp>;
}>;

export type GetConnectedAccountsQuery = {
  __typename?: 'query_root';
  app_accounts_aggregate: {
    __typename?: 'app_accounts_aggregate';
    aggregate?: { __typename?: 'app_accounts_aggregate_fields'; count: number } | null | undefined;
  };
  app_accounts: Array<{ __typename?: 'app_accounts' } & AppAccountFragment>;
};

export type GetInviteQueryVariables = Exact<{
  code: Scalars['String'];
}>;

export type GetInviteQuery = {
  __typename?: 'query_root';
  getInvite: { __typename?: 'InviteCodeOutput'; expired_at: string; team_name: string };
};

export type GetSettingsQueryVariables = Exact<{
  teamId: Scalars['String'];
}>;

export type GetSettingsQuery = {
  __typename?: 'query_root';
  teamInfo?:
    | ({
        __typename?: 'teams';
        subscription?: ({ __typename?: 'subscriptions' } & SubscriptionFragment) | null | undefined;
      } & TeamFragment)
    | null
    | undefined;
  team_members: Array<{ __typename?: 'team_members' } & TeamMemberFragment>;
};

export type GetTotalAccountConnectedQueryVariables = Exact<{
  teamId: Scalars['String'];
  appId: Scalars['uuid'];
}>;

export type GetTotalAccountConnectedQuery = {
  __typename?: 'query_root';
  app_accounts: {
    __typename?: 'app_accounts_aggregate';
    connected?: { __typename?: 'app_accounts_aggregate_fields'; count: number } | null | undefined;
  };
};

export const ImageFragmentDoc = gql`
  fragment Image on files {
    id
    mime_type
    path
  }
`;
export const AppFragmentDoc = gql`
  fragment App on apps {
    id
    display_name
    description
    created_at
    is_live
    name
    scopes
    secret_key
    server_ip
    team_id
    updated_at
    icon {
      ...Image
    }
  }
  ${ImageFragmentDoc}
`;
export const AppAccountFragmentDoc = gql`
  fragment AppAccount on app_accounts {
    id
    provider_credential {
      provider_name
    }
    app_user {
      id
      app {
        id
        display_name
        icon {
          ...Image
        }
      }
    }
    is_connected
    created_at
    updated_at
  }
  ${ImageFragmentDoc}
`;
export const ProviderFragmentDoc = gql`
  fragment Provider on providers {
    id
    name
    display_name
    type_id
    auth_type_id
    is_activate
  }
`;
export const PlanFragmentDoc = gql`
  fragment Plan on plans {
    fee_monthly
    fee_monthly_accounts_connection
    free_monthly_accounts
    id
    limit_app
    name
    limit_member
  }
`;
export const SubscriptionFragmentDoc = gql`
  fragment Subscription on subscriptions {
    id
    plan_id
    subscribe_after_trial
    subscribed_at
    trial_period_end_date
    trial_period_start_date
    team_id
    unsubscribed_at
    updated_at
    valid_to
    plan {
      ...Plan
    }
  }
  ${PlanFragmentDoc}
`;
export const TeamFragmentDoc = gql`
  fragment Team on teams {
    id
    name
    created_at
    updated_at
    is_activation_fee
    is_subscribed
    is_trial
    support_email
    support_phone_number
    status_id
    logo {
      ...Image
    }
  }
  ${ImageFragmentDoc}
`;
export const TeamMemberFragmentDoc = gql`
  fragment TeamMember on team_members {
    id
    email
    role_id
    status_id
    user {
      id
      full_name
      provider
      avatar {
        ...Image
      }
    }
  }
  ${ImageFragmentDoc}
`;
export const UserFragmentDoc = gql`
  fragment User on users {
    id
    full_name
    company_name
    email
    created_at
    phone_number
    role_id
    updated_at
    avatar {
      ...Image
    }
  }
  ${ImageFragmentDoc}
`;
export const ChangeMemberRoleDocument = gql`
  mutation changeMemberRole($arg: MemberRoleInput!) {
    changeMemberRole(arg: $arg) {
      team_member_id
      team_member {
        ...TeamMember
      }
    }
  }
  ${TeamMemberFragmentDoc}
`;

export function useChangeMemberRoleMutation() {
  return Urql.useMutation<ChangeMemberRoleMutation, ChangeMemberRoleMutationVariables>(
    ChangeMemberRoleDocument
  );
}
export const CreateNewAppDocument = gql`
  mutation createNewApp($object: apps_insert_input!) {
    insert_apps_one(object: $object) {
      ...App
    }
  }
  ${AppFragmentDoc}
`;

export function useCreateNewAppMutation() {
  return Urql.useMutation<CreateNewAppMutation, CreateNewAppMutationVariables>(
    CreateNewAppDocument
  );
}
export const CreatePresignedUrlDocument = gql`
  mutation createPresignedUrl($fileSize: Int!) {
    createPresignedUrl(arg: { fileType: ICON, fileSize: $fileSize }) {
      file_id
      postURL
      formData
      file {
        ...Image
      }
    }
  }
  ${ImageFragmentDoc}
`;

export function useCreatePresignedUrlMutation() {
  return Urql.useMutation<CreatePresignedUrlMutation, CreatePresignedUrlMutationVariables>(
    CreatePresignedUrlDocument
  );
}
export const DeleteAppDocument = gql`
  mutation deleteApp($appId: uuid!) {
    update_apps_by_pk(pk_columns: { id: $appId }, _set: { is_deleted: true }) {
      ...App
    }
  }
  ${AppFragmentDoc}
`;

export function useDeleteAppMutation() {
  return Urql.useMutation<DeleteAppMutation, DeleteAppMutationVariables>(DeleteAppDocument);
}
export const DeleteTeamMemberDocument = gql`
  mutation deleteTeamMember($team_member_id: uuid!) {
    deleteTeamMember(team_member_id: $team_member_id) {
      is_deleted
    }
  }
`;

export function useDeleteTeamMemberMutation() {
  return Urql.useMutation<DeleteTeamMemberMutation, DeleteTeamMemberMutationVariables>(
    DeleteTeamMemberDocument
  );
}
export const ReSendTeamInviteDocument = gql`
  mutation reSendTeamInvite($team_member_id: uuid!) {
    reSendTeamInvite(team_member_id: $team_member_id)
  }
`;

export function useReSendTeamInviteMutation() {
  return Urql.useMutation<ReSendTeamInviteMutation, ReSendTeamInviteMutationVariables>(
    ReSendTeamInviteDocument
  );
}
export const ReplyInviteDocument = gql`
  mutation replyInvite($code: String!, $isAgree: Boolean!) {
    replyInvite(code: $code, isAgree: $isAgree) {
      team_member_id
    }
  }
`;

export function useReplyInviteMutation() {
  return Urql.useMutation<ReplyInviteMutation, ReplyInviteMutationVariables>(ReplyInviteDocument);
}
export const ResetSecretKeyDocument = gql`
  mutation resetSecretKey($appId: uuid!) {
    resetSecretKey(appId: $appId) {
      app_id
      app {
        ...App
      }
    }
  }
  ${AppFragmentDoc}
`;

export function useResetSecretKeyMutation() {
  return Urql.useMutation<ResetSecretKeyMutation, ResetSecretKeyMutationVariables>(
    ResetSecretKeyDocument
  );
}
export const SendTeamInviteDocument = gql`
  mutation sendTeamInvite($email: String!, $teamId: String!) {
    sendTeamInvite(email: $email, teamId: $teamId) {
      team_member_id
      member {
        ...TeamMember
      }
    }
  }
  ${TeamMemberFragmentDoc}
`;

export function useSendTeamInviteMutation() {
  return Urql.useMutation<SendTeamInviteMutation, SendTeamInviteMutationVariables>(
    SendTeamInviteDocument
  );
}
export const SignUpDocument = gql`
  mutation signUp($token: String!, $data: SignUpInput!) {
    signUp(token: $token, data: $data) {
      userId
    }
  }
`;

export function useSignUpMutation() {
  return Urql.useMutation<SignUpMutation, SignUpMutationVariables>(SignUpDocument);
}
export const SwitchAccountConnectStatusDocument = gql`
  mutation switchAccountConnectStatus($is_connected: Boolean = false, $id: uuid!) {
    update_app_accounts_by_pk(_set: { is_connected: $is_connected }, pk_columns: { id: $id }) {
      ...AppAccount
    }
  }
  ${AppAccountFragmentDoc}
`;

export function useSwitchAccountConnectStatusMutation() {
  return Urql.useMutation<
    SwitchAccountConnectStatusMutation,
    SwitchAccountConnectStatusMutationVariables
  >(SwitchAccountConnectStatusDocument);
}
export const SwitchAppLiveDocument = gql`
  mutation switchAppLive($arg: AppLiveInput!) {
    switchAppLive(arg: $arg) {
      app_id
      app {
        ...App
      }
    }
  }
  ${AppFragmentDoc}
`;

export function useSwitchAppLiveMutation() {
  return Urql.useMutation<SwitchAppLiveMutation, SwitchAppLiveMutationVariables>(
    SwitchAppLiveDocument
  );
}
export const UpdateAppDocument = gql`
  mutation updateApp($appId: uuid!, $vars: apps_set_input = {}) {
    update_apps_by_pk(pk_columns: { id: $appId }, _set: $vars) {
      ...App
    }
  }
  ${AppFragmentDoc}
`;

export function useUpdateAppMutation() {
  return Urql.useMutation<UpdateAppMutation, UpdateAppMutationVariables>(UpdateAppDocument);
}
export const UpdateTeamInfoDocument = gql`
  mutation updateTeamInfo($teamId: String!, $_set: teams_set_input = {}) {
    update_teams_by_pk(pk_columns: { id: $teamId }, _set: $_set) {
      ...Team
    }
  }
  ${TeamFragmentDoc}
`;

export function useUpdateTeamInfoMutation() {
  return Urql.useMutation<UpdateTeamInfoMutation, UpdateTeamInfoMutationVariables>(
    UpdateTeamInfoDocument
  );
}
export const UpdateUserInfoDocument = gql`
  mutation updateUserInfo($userId: String!, $_set: users_set_input = {}) {
    user: update_users_by_pk(pk_columns: { id: $userId }, _set: $_set) {
      ...User
    }
  }
  ${UserFragmentDoc}
`;

export function useUpdateUserInfoMutation() {
  return Urql.useMutation<UpdateUserInfoMutation, UpdateUserInfoMutationVariables>(
    UpdateUserInfoDocument
  );
}
export const GetAccountConnectedStatisticsDocument = gql`
  query GetAccountConnectedStatistics($teamId: String!) {
    statistics: accountconnectedstatistics(where: { team_id: { _eq: $teamId } }) {
      count
      provider_name
      team_id
    }
  }
`;

export function useGetAccountConnectedStatisticsQuery(
  options: Omit<Urql.UseQueryArgs<GetAccountConnectedStatisticsQueryVariables>, 'query'> = {}
) {
  return Urql.useQuery<GetAccountConnectedStatisticsQuery>({
    query: GetAccountConnectedStatisticsDocument,
    ...options,
  });
}
export const GetAppDocument = gql`
  query getApp($id: uuid!) {
    app: apps_by_pk(id: $id) {
      ...App
    }
  }
  ${AppFragmentDoc}
`;

export function useGetAppQuery(
  options: Omit<Urql.UseQueryArgs<GetAppQueryVariables>, 'query'> = {}
) {
  return Urql.useQuery<GetAppQuery>({ query: GetAppDocument, ...options });
}
export const GetAppsDocument = gql`
  query getApps($teamId: String!) {
    apps(where: { team_id: { _eq: $teamId } }, order_by: { created_at: asc }) {
      ...App
    }
  }
  ${AppFragmentDoc}
`;

export function useGetAppsQuery(
  options: Omit<Urql.UseQueryArgs<GetAppsQueryVariables>, 'query'> = {}
) {
  return Urql.useQuery<GetAppsQuery>({ query: GetAppsDocument, ...options });
}
export const GetProvidersDocument = gql`
  query GetProviders {
    providers {
      ...Provider
    }
  }
  ${ProviderFragmentDoc}
`;

export function useGetProvidersQuery(
  options: Omit<Urql.UseQueryArgs<GetProvidersQueryVariables>, 'query'> = {}
) {
  return Urql.useQuery<GetProvidersQuery>({ query: GetProvidersDocument, ...options });
}
export const GetTeamsDocument = gql`
  query GetTeams($user_id: String!) {
    teams {
      id
      name
      members(limit: 1, where: { user_id: { _eq: $user_id } }) {
        role_id
        user_id
      }
    }
  }
`;

export function useGetTeamsQuery(
  options: Omit<Urql.UseQueryArgs<GetTeamsQueryVariables>, 'query'> = {}
) {
  return Urql.useQuery<GetTeamsQuery>({ query: GetTeamsDocument, ...options });
}
export const GetInitDataDocument = gql`
  query GetInitData($user_id: String!) {
    profile: users_by_pk(id: $user_id) {
      ...User
    }
    teams {
      id
      name
      members(limit: 1, where: { user_id: { _eq: $user_id } }) {
        id
        role_id
        user_id
        created_at
      }
      is_trial
      is_subscribed
      logo_id
      logo {
        ...Image
      }
    }
  }
  ${UserFragmentDoc}
  ${ImageFragmentDoc}
`;

export function useGetInitDataQuery(
  options: Omit<Urql.UseQueryArgs<GetInitDataQueryVariables>, 'query'> = {}
) {
  return Urql.useQuery<GetInitDataQuery>({ query: GetInitDataDocument, ...options });
}
export const GetAccountConnectStatisticDocument = gql`
  query getAccountConnectStatistic(
    $yesterday: timestamptz!
    $startThisMonth: timestamptz!
    $startLastMonth: timestamptz!
    $startThisWeek: timestamptz!
    $startLastWeek: timestamptz!
    $teamId: String!
  ) {
    yesterdayTotal: app_accounts_aggregate(
      where: { created_at: { _lte: $yesterday }, app_user: { app: { team_id: { _eq: $teamId } } } }
    ) {
      account: aggregate {
        count
      }
    }
    todayTotal: app_accounts_aggregate(
      where: { app_user: { app: { team_id: { _eq: $teamId } } } }
    ) {
      account: aggregate {
        count
      }
    }
    thisMonthTotal: app_accounts_aggregate(
      where: {
        created_at: { _gte: $startThisMonth }
        app_user: { app: { team_id: { _eq: $teamId } } }
      }
    ) {
      account: aggregate {
        count
      }
    }
    lastMonthTotal: app_accounts_aggregate(
      where: {
        _and: [{ created_at: { _gte: $startLastMonth } }, { created_at: { _lt: $startThisMonth } }]
        app_user: { app: { team_id: { _eq: $teamId } } }
      }
    ) {
      account: aggregate {
        count
      }
    }
    thisWeekTotal: app_accounts_aggregate(
      where: {
        created_at: { _gte: $startThisWeek }
        app_user: { app: { team_id: { _eq: $teamId } } }
      }
    ) {
      account: aggregate {
        count
      }
    }
    lastLastWeekTotal: app_accounts_aggregate(
      where: {
        _and: [{ created_at: { _gte: $startLastWeek } }, { created_at: { _lt: $startThisWeek } }]
        app_user: { app: { team_id: { _eq: $teamId } } }
      }
    ) {
      account: aggregate {
        count
      }
    }
  }
`;

export function useGetAccountConnectStatisticQuery(
  options: Omit<Urql.UseQueryArgs<GetAccountConnectStatisticQueryVariables>, 'query'> = {}
) {
  return Urql.useQuery<GetAccountConnectStatisticQuery>({
    query: GetAccountConnectStatisticDocument,
    ...options,
  });
}
export const GetConnectedAccountsDocument = gql`
  query getConnectedAccounts(
    $offset: Int = 10
    $limit: Int = 15
    $teamId: String!
    $appIdExp: uuid_comparison_exp = {}
  ) {
    app_accounts_aggregate(
      where: { app_user: { app: { team_id: { _eq: $teamId } }, id: $appIdExp } }
    ) {
      aggregate {
        count
      }
    }
    app_accounts(
      limit: $limit
      offset: $offset
      where: { app_user: { app: { team_id: { _eq: $teamId } }, id: $appIdExp } }
    ) {
      ...AppAccount
    }
  }
  ${AppAccountFragmentDoc}
`;

export function useGetConnectedAccountsQuery(
  options: Omit<Urql.UseQueryArgs<GetConnectedAccountsQueryVariables>, 'query'> = {}
) {
  return Urql.useQuery<GetConnectedAccountsQuery>({
    query: GetConnectedAccountsDocument,
    ...options,
  });
}
export const GetInviteDocument = gql`
  query getInvite($code: String!) {
    getInvite(code: $code) {
      expired_at
      team_name
    }
  }
`;

export function useGetInviteQuery(
  options: Omit<Urql.UseQueryArgs<GetInviteQueryVariables>, 'query'> = {}
) {
  return Urql.useQuery<GetInviteQuery>({ query: GetInviteDocument, ...options });
}
export const GetSettingsDocument = gql`
  query getSettings($teamId: String!) {
    teamInfo: teams_by_pk(id: $teamId) {
      ...Team
      subscription {
        ...Subscription
      }
    }
    team_members(where: { team_id: { _eq: $teamId } }) {
      ...TeamMember
    }
  }
  ${TeamFragmentDoc}
  ${SubscriptionFragmentDoc}
  ${TeamMemberFragmentDoc}
`;

export function useGetSettingsQuery(
  options: Omit<Urql.UseQueryArgs<GetSettingsQueryVariables>, 'query'> = {}
) {
  return Urql.useQuery<GetSettingsQuery>({ query: GetSettingsDocument, ...options });
}
export const GetTotalAccountConnectedDocument = gql`
  query getTotalAccountConnected($teamId: String!, $appId: uuid!) {
    app_accounts: app_accounts_aggregate(
      where: {
        app_user: { app: { team_id: { _eq: $teamId }, id: { _eq: $appId } } }
        is_connected: { _eq: true }
      }
    ) {
      connected: aggregate {
        count
      }
    }
  }
`;

export function useGetTotalAccountConnectedQuery(
  options: Omit<Urql.UseQueryArgs<GetTotalAccountConnectedQueryVariables>, 'query'> = {}
) {
  return Urql.useQuery<GetTotalAccountConnectedQuery>({
    query: GetTotalAccountConnectedDocument,
    ...options,
  });
}
