import { useContext, useCallback } from 'react';
import { AppContext, Team } from '../AppContext';
import { Team_Roles_Enum } from 'client/user';

export const useTeam = () => {
  const { initData, setInitData } = useContext(AppContext);
  const getTeamPath = useCallback(
    (path: string) => {
      return `${path}?i=${initData?.currentTeam?.id}`;
    },
    [initData]
  );
  const switchTeam = useCallback(
    (teamId: string) => {
      if (initData && teamId && teamId !== initData.currentTeam.id) {
        const teamIndex = initData.teams.findIndex((t) => t.id === teamId);
        if (teamIndex >= 0) {
          setInitData({ ...initData, currentTeam: initData.teams[teamIndex] });
        }
      }
    },
    [initData, setInitData]
  );
  return { getTeamPath, switchTeam, currentTeam: initData?.currentTeam, teams: initData?.teams };
};

export { Team_Roles_Enum };
export type { Team };
