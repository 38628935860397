import { Center, Flex, useColorModeValue as mode, Stack } from '@chakra-ui/react';
import { Suspense, FC, useEffect } from 'react';
import { renderRoutes, RouteConfigComponentProps } from 'react-router-config';
import { MobileTopBar } from './MobileTopBar';
import { Sidebar } from './Sidebar';
import { useAuth } from 'hooks/useAuth';
import { useHistory } from 'react-router-dom';
import { PATH } from 'Paths';
import { Icon } from 'Icon';

const Loading = () => {
  return (
    <Center w="full" minH="100vh">
      <Icon mt="-10" />
    </Center>
  );
};

export const DashboardLayout: FC<RouteConfigComponentProps> = ({ route }) => {
  const { isFirebaseInit, isSignedIn } = useAuth();
  const { replace, location } = useHistory();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  useEffect(() => {
    if (isFirebaseInit) {
      if (!isSignedIn) {
        replace(PATH.AUTH_SIGN_IN);
      }
    }
  }, [isFirebaseInit, isSignedIn, replace]);

  return (
    <Flex minH="100vh" flexDirection="column" bg={mode('gray.50', 'gray.800')}>
      <MobileTopBar />
      <Flex flex="1" overflow="hidden" minH="100vh">
        <Sidebar display={{ base: 'none', md: 'flex' }} />
        <Flex
          flex="1"
          p={{ base: 4, md: 8 }}
          w="full"
          minH="100vh"
          ml={{ base: '0', md: '64' }}
          flexDirection="column"
          overflow="hidden"
          bg={mode('while', 'gray.600')}
          alignItems="center">
          <Stack maxW="7xl" w="full" spacing={{ base: 4, md: 10 }}>
            {isFirebaseInit && isSignedIn ? (
              <Suspense fallback={<Loading />}>{renderRoutes(route?.routes)}</Suspense>
            ) : (
              <Loading />
            )}
          </Stack>
        </Flex>
      </Flex>
    </Flex>
  );
};
