import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import dayjs from 'dayjs';
import 'dayjs/locale/ms';
import common from './en/common.json';
import router from './en/router.json';
import signin from './en/signin.json';
import signup from './en/signup.json';
import firebase from './en/firebase.json';
import getStarted from './en/get-started.json';
import sidebar from './en/sidebar.json';
import dash from './en/dash.json';
import apps from './en/apps.json';
import account from './en/account.json';
import setting from './en/setting.json';
import billing from './en/billing.json';
import invite from './en/invite.json';
import plan from './en/plan.json';
import profile from './en/profile.json';

export const resources = {
  en: {
    common,
    router,
    signin,
    signup,
    firebase,
    getStarted,
    sidebar,
    dash,
    apps,
    account,
    setting,
    billing,
    invite,
    plan,
    profile,
  },
};

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    lng: 'en',
    ns: ['common', 'router', 'signin', 'signup', 'firebase', 'getStarted', 'sidebar', 'dash', 'apps', 'account', 'setting', 'billing', 'invite', 'plan', 'profile'],
    resources,
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false,
    },
    react: {
      useSuspense: true,
    },
  });

i18n.on('languageChanged', (lng) => {
  dayjs.locale(lng);
});
